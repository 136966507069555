import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import moment from "moment-timezone";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { Modal, Button } from "antd";

const SubmittedCase = () => {
  const { loginData } = useSelector((store) => store.auth);
  const { submittedCases, assignedAgent } = useSelector((store) => store.cases);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [urlPath, setUrlPath] = useState('')

  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [filteredCase, setFilteredCase] = useState([]);
 const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCase, setSelectedCase] = useState('');

  const showModal = (data) => {
    setSelectedCase(data)
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const updateClick = async () => {
    const url = `${Baseurl}/api/v1/cases/deletecase/${selectedCase._id}`
    const resp = await axios.delete(url)
    console.log(resp.data);
    if (resp.data.success) {
      setIsModalOpen(false);
      setSelectedCase('')
      window.location.reload()
      // alert(`Case of ${selectedCase.requesterName} deleted succesfully`)
    }
  }
 
  useEffect(() => {
    const getAllReports = submittedCases.slice().reverse()
    const data = getAllReports.filter((indCase) =>
      loginData?.permittedState?.includes(indCase.state)
    )
    setFilterData(data)
    setFilteredCase(data)
  }, [submittedCases])


  const handleSearch = (e) => {
    if (search.trim() !== '') {
      const filterBySearch = filterData.filter((indData) =>
        indData.regdNo.toUpperCase() === search.trim().toUpperCase()
      )
      setFilterData(filterBySearch)
    } else {
      alert('enter vehicle no.')
    }
  }

  const handleReset = () => {
    setFilterData(filteredCase)
    setSearch('')
  }

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full" style={{ marginBottom: '60px' }}>
        <div className="p-2 space-y-6">
          <div className="flex items-center justify-between w-full print:hidden">
            <h4 className="text-lg font-semibold text-default-900">
              Dashboard
            </h4>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}>
              <input type="text" placeholder="Enter vehicle no. " value={search} onChange={(e) => setSearch(e.target.value)} style={{ border: '1px solid #dd5536', borderRadius: '10px' }} />
              <button onClick={() => handleSearch()} className="text-white px-4 py" style={{ padding: '10px 15px', backgroundColor: '#e6632e', borderRadius: '10px' }}>Search </button>
              <button onClick={() => handleReset()} className="text-white px-4 py" style={{ padding: '10px 15px', backgroundColor: '#E20808', borderRadius: '10px' }}>Reset </button>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1">
          <div className="shadow rounded-lg bg-white dark:bg-default-50">
            <div className="border-t border-dashed border-default-200">
              <div className="relative overflow-x-auto">
                <table className="min-w-full overflow-x-hidden">
                  <thead className="border-b border-dashed border-default-200">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                      >
                        No
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-40"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Mobile
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        State
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Vehicle No.
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Report Requested By
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Vehicle Preview
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Signature
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Edit
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-dashed divide-default-200">
                    {filterData &&
                      filterData.map((admin, index) => (
                        <tr key={index}>
                          <td className="px-6 py-1 text-center text-default-600 font-semibold whitespace-nowrap"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            <b>{admin.caseId}</b>
                          </td>
                          <td className="px-6 py-1 text-center whitespace-nowrap"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            <h6 className="text-sm font-semibold text-default-700">
                              {admin.name.length > 30 ? (
                                <>{admin.name.slice(0, 30)}...</>
                              ) : (
                                <>{admin.name.slice(0, 30)}</>
                              )}
                            </h6>
                          </td>
                          <td className="px-6 py-1 text-center text-default-600 font-medium whitespace-nowrap"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            {admin.mobile}
                          </td>
                          <td
                            className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#f2f2f2" : "#fff",
                            }}
                          >
                            {moment(admin.createdAt)
                              .tz("Asia/Kolkata")
                              .format("YYYY-MM-DD HH:mm:ss")}
                          </td>
                          <td className="px-6 py-1 text-center text-default-600 font-medium whitespace-nowrap"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            {admin.email.length > 18 ? (
                              <>{admin.email.slice(0, 18)}...</>
                            ) : (
                              <>{admin.email.slice(0, 18)}</>
                            )}
                          </td>
                          <td className="px-6 py-1 text-center text-default-600 font-medium whitespace-nowrap"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            <div className="d-flex m-auto">
                              {admin.state}
                            </div>
                          </td>
                          <td className="px-6 py-1 text-center text-default-600 font-medium whitespace-nowrap"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            <div className="d-flex m-auto">
                              {admin.regdNo}
                            </div>
                          </td>
                          <td className="px-6 py-1 text-center text-default-600 font-medium whitespace-nowrap"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            <div className="d-flex m-auto">
                              {admin.requesterName}
                            </div>
                          </td>
                          <td className="px-6 py-1 text-default-600 font-medium whitespace-nowrap"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            <div className="d-flex m-auto">
                              <img
                                src={admin.images.frontImage}
                                style={{ height: "40px", width: "60px" }}
                                alt=""
                              />
                            </div>
                          </td>
                          <td className="px-6 py-1 text-default-600 font-medium whitespace-nowrap"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            <div className="d-flex m-auto">
                              <img
                                src={admin.signNdoc.sign}
                                style={{ height: "40px", width: "60px" }}
                                alt=""
                              />
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-1 px-3 text-center text-sm font-medium"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            <div className="flex items-center justify-center gap-2">
                              <button className="inline-flex items-center justify-center p-3 rounded-md bg-cyan-500/20 text-cyan-500 transition-all duration-200 hover:bg-cyan-500 hover:text-white"

                              >
                                <Link to={`/admin/editreport/${admin._id}`}>
                                  Edit Report
                                </Link>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-between gap-6 py-3 px-6 border-t border-dashed border-default-200">
            </div>
          </div>
        </div>
        <Modal title="Delete Case"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button
              style={{
                padding: "10px 15px",
                color: "#fff",
                backgroundColor: "#FF0000",
              }}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>,
            <Button
              style={{
                padding: "10px 15px",
                color: "#fff",
                backgroundColor: "#04681e",
              }}
              onClick={(e) => updateClick(e)}
            >
              Confirm
            </Button>,
          ]}
        >
          <p>Are you sure you want to delete case of {selectedCase.requesterName}</p>
        </Modal>
      </div>
    </>
  );
};

export default SubmittedCase;
