import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { CaseActions, sendVehicleNumber } from "../../redux/Cases/CaseSlice";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { InputAdornment, TextField } from "@mui/material";

const EditReport = () => {
  const { vehicleInfo } = useSelector((store) => store.cases);
  const { totalClients } = useSelector((store) => store.client);
  const { employeeList } = useSelector((store) => store.employee);
  const { loginData, role } = useSelector((store) => store.auth);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [assetType, setAssetType] = useState("");
  const [commercialSubAssetType, setCommercialSubAssetType] = useState("");
  const [caseType, setCaseType] = useState("");
  const [valuationPurpose, setValuationPurposeType] = useState(
    "Assesment of fair market value"
  );
  const [caseSubmitterName, setCaseSubmitterName] = useState("");
  const [requesterID, setRequesterID] = useState("");
  const [requesterName, setRequesterName] = useState("");
  const [requesterNameError, setRequesterNameError] = useState("");
  const [requesterBranch, setRequesterBranch] = useState("");
  const [requesterBranchError, setRequesterBranchError] = useState("");
  const [requesterPhone, setRequesterPhone] = useState("");
  const [requesterPhoneError, setRequesterPhoneError] = useState("");
  const [name, setName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [vehicleLocation, setVehicleLocation] = useState("");
  const [vehicleRegdNo, setVehicleRegdNo] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [variant, setVariant] = useState("");
  const [vehicleData, setVehicleData] = useState({});
  const [dataFetched, setDataFetched] = useState(false);
  const [revenue, setRevenue] = useState(0);
  const [cost, setCost] = useState(0);
  const [clientList, setClientList] = useState([]);
  useEffect(() => {
    const client = totalClients.map((indClient, index) => ({
      id: index,
      _id:indClient._id,
      name: indClient.name,
      phone: indClient.phone,
      organisation: indClient.organisation,
      TwoWheeler: indClient.twoWheelerCharge,
      FourWheeler: indClient.fourWheelerCharge,
      commercialSCVVehicleCharge: indClient.commercialSCVVehicleCharge,
      commercialHCVVehicleCharge: indClient.commercialHCVVehicleCharge,
      commercialLCVVehicleCharge: indClient.commercialLCVVehicleCharge,
      ConstructionEquipment: indClient.constructionEqpCharge,
      ThreeWheeler: indClient.threeWheelerCharge,
      FarmEquipment: indClient.farmEqpCharge,
    }));
    setClientList(client);
  }, [totalClients]);
  const handleEmployeeChange = (event) => {
    const selectedClient = employeeList.find(emp => emp.name === event.target.value);
    handleSubmitter(selectedClient); // Pass the whole object to your function
    setCaseSubmitterName(event.target.value); // Optionally, set the name if needed
  };
  const handleSubmitter = (selectedSubmitter) => {
    const { charges } = selectedSubmitter;
    console.log(charges, 'charges');
    if (assetType === "Two Wheeler") {
      const stateCharge = charges.find(
        (indCharge) => indCharge.state === state
      );
      if (stateCharge) {
        setCost(Number(stateCharge?.twoWheelerCharge));
      } else {
        setCost(0);
      }
    } else if (assetType === "Four Wheeler") {
      const stateCharge = charges.find(
        (indCharge) => indCharge.state === state
      );
      if (stateCharge) {
        setCost(Number(stateCharge?.fourWheelerCharge));
      } else {
        setCost(0);
      }
    } else if (
      assetType === "Commercial" &&
      commercialSubAssetType === "Commercial SCV"
    ) {
      const stateCharge = charges.find(
        (indCharge) => indCharge.state === state
      );
      if (stateCharge) {
        setCost(Number(stateCharge?.commercialSCVVehicleCharge));
      } else {
        setCost(0);
      }
    } else if (
      assetType === "Commercial" &&
      commercialSubAssetType === "Commercial LCV"
    ) {
      const stateCharge = charges.find(
        (indCharge) => indCharge.state === state
      );
      if (stateCharge) {
        setCost(Number(stateCharge?.commercialLCVVehicleCharge));
      } else {
        setCost(0);
      }
    } else if (
      assetType === "Commercial" &&
      commercialSubAssetType === "Commercial HCV"
    ) {
      const stateCharge = charges.find(
        (indCharge) => indCharge.state === state
      );
      if (stateCharge) {
        setCost(Number(stateCharge?.commercialHCVVehicleCharge));
      } else {
        setCost(0);
      }
    } else if (assetType === "Construction Equipment") {
      const stateCharge = charges.find(
        (indCharge) => indCharge.state === state
      );
      if (stateCharge) {
        setCost(Number(stateCharge?.constructionEqpCharge));
      } else {
        setCost(0);
      }
    } else if (assetType === "Farm Equipment") {
      const stateCharge = charges.find(
        (indCharge) => indCharge.state === state
      );
      if (stateCharge) {
        setCost(Number(stateCharge?.farmEqpCharge));
      } else {
        setCost(0);
      }
    } else if (assetType === "Three Wheeler") {
      const stateCharge = charges.find(
        (indCharge) => indCharge.state === state
      );
      if (stateCharge) {
        setCost(Number(stateCharge?.threeWheelerCharge));
      } else {
        setCost(0);
      }
    }
  };
  const handleChange = (event) => {
    const selectedClient = clientList.find(client => client._id === event.target.value);
    handleRequester(selectedClient); 
    setRequesterID(event.target.value); // Optionally, set the name if needed
  };
  const handleRequester = (selectedRequester) => {
    const {
      organisation,
      name,
      phone,
      TwoWheeler,
      FourWheeler,
      commercialSCVVehicleCharge,
      commercialHCVVehicleCharge,
      commercialLCVVehicleCharge,
      ConstructionEquipment,
      FarmEquipment,
      ThreeWheeler,
    } = selectedRequester;

    setRequesterName(organisation);
    if (assetType === "Two Wheeler") {
      setRevenue(Number(TwoWheeler));
    } else if (assetType === "Four Wheeler") {
      setRevenue(Number(FourWheeler));
    } else if (
      assetType === "Commercial" &&
      commercialSubAssetType === "Commercial SCV"
    ) {
      setRevenue(Number(commercialSCVVehicleCharge));
    } else if (
      assetType === "Commercial" &&
      commercialSubAssetType === "Commercial LCV"
    ) {
      setRevenue(Number(commercialLCVVehicleCharge));
    } else if (
      assetType === "Commercial" &&
      commercialSubAssetType === "Commercial HCV"
    ) {
      setRevenue(Number(commercialHCVVehicleCharge));
    } else if (assetType === "Construction Equipment") {
      setRevenue(Number(ConstructionEquipment));
    } else if (assetType === "Farm Equipment") {
      setRevenue(Number(FarmEquipment));
    } else if (assetType === "Three Wheeler") {
      setRevenue(Number(ThreeWheeler));
    }
  };

  const handleRequesterPhn = async (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setRequesterPhone(value);
  };
  useEffect(() => {
    const caseId = params.id;
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/cases/individualcase/${caseId}`;
      const caseFetch = await axios.get(url);
      const data = caseFetch.data.case;
      dispatch(CaseActions.setCurrCase(caseFetch.data.case));
      setAssetType(data.assetType);
      setCaseType(data.caseType);
      setValuationPurposeType(data.valuationPurpose);
      setCaseSubmitterName(data?.fieldServiceBy);
      setRequesterName(data?.requesterName);
      setRequesterPhone(data?.requesterPhone);
      setRequesterBranch(data?.requesterBranch);
      setName(data.name);
      setMobileNo(data.mobile);
      setEmail(data.email);
      setAddress(data.address);
      setState(data.state);
      setCity(data.city);
      setVehicleLocation(data.vehicleLocation);
      setVehicleRegdNo(data.regdNo);
      setMake(data.brand);
      setModel(data.model);
      setVariant(data.variant);
    };
    fetchData();
  }, [params.id]);

  // useEffect(() => {
  //     if (vehicleRegdNo.length === 9 || vehicleRegdNo.length === 10) {

  //         const getData = async () => {
  //             const vehicleNo = vehicleRegdNo.toLocaleUpperCase()
  //             let data = JSON.stringify({
  //                 "vehicleNumber": vehicleNo
  //             });
  //             let config = {
  //                 method: 'post',
  //                 maxBodyLength: Infinity,
  //                 url: 'https://api.invincibleocean.com/invincible/vehicleRcV6',
  //                 headers: {
  //                     'Content-Type': 'application/json',
  //                     'clientId': '7383c6f956c453a5689f4da6843b9f54:a3c36d6f8cc8dcf5ad904e5ab22150a0',
  //                     'secretKey': 'fvCtpjNbHpAVgoJiZJZPWNIjLsJO3VYqem6PsrENWSv54TYqehEomZCUtIwwegRT9'
  //                 },
  //                 data: data
  //             };
  //             axios.request(config)
  //                 .then((response) => {

  //                     setVehicleData(response.data.result.data)
  //                 })
  //                 .catch((error) => {
  //                 });
  //         }
  //         getData()
  //         setDataFetched(true)
  //     }
  // }, [vehicleRegdNo])

  // const handleClick = () => {
  //     window.open('', '_blank');
  // };

  const handleRegdNo = async (e) => {
    let regdNo = e.target.value;
    setVehicleRegdNo(e.target.value);
    if (regdNo.length === 9 || regdNo.length === 10) {
      const formData = {
        regdNo: regdNo,
      };
      const vehicle = await dispatch(sendVehicleNumber(formData));
      if (vehicle.payload.response.result !== undefined) {
        const sendData = await dispatch(
          CaseActions.setVehicleInfo(vehicle.payload.response.result)
        );
        setVehicleData(vehicle.payload.response.result);
        setDataFetched(true);
      } else {
        alert("Vehicle Details are not able to fetch through Vahan API");
        setVehicleData({});
        setDataFetched(true);
      }
    }
  };
  const selectAssetType = [
    {
      id: 1,
      name: "Two Wheeler",
    },
    {
      id: 2,
      name: "Four Wheeler",
    },
    {
      id: 3,
      name: "Commercial",
    },
    {
      id: 4,
      name: "Construction Equipment",
    },
    {
      id: 5,
      name: "Three Wheeler",
    },
    {
      id: 6,
      name: "Farm Equipment",
    },
    {
      id: 7,
      name: "Car Taxi",
    },
    {
      id: 8,
      name: "Motor Cab",
    },
  ];
  const selectCommercialAssetType = [
    {
      id: 1,
      name: "Commercial SCV",
    },
    {
      id: 2,
      name: "Commercial LCV",
    },
    {
      id: 3,
      name: "Commercial HCV",
    },
  ];

  const selectAssetTypeChange = (e) => {
    const { value } = e.target;
    setAssetType(value);
  };
  const selectCommercialAssetTypeChange = (e) => {
    const { value } = e.target;
    setCommercialSubAssetType(value);
    alert("Please select valution requested by");
  };

  const selectCaseType = [
    {
      id: 1,
      name: "Revaluation",
    },
    {
      id: 2,
      name: "Repossession",
    },
    {
      id: 3,
      name: "Retail",
    },
    {
      id: 4,
      name: "Insurance Idv",
    },
    {
      id: 5,
      name: "Repurchase",
    },
    {
      id: 5,
      name: "Inspection",
    },
    {
      id: 5,
      name: "Pre Inspection",
    },
  ];
  const selectCaseChange = (e) => {
    const { value } = e.target;
    setCaseType(value);
  };

  const handleDetails = () => {
    if (!caseSubmitterName) {
      alert("Please select case submitter name");
    } else if (!requesterName) {
      alert("Please select valuation requester name");
    }
  };

  const handleCaseUpdate = async () => {
    if (dataFetched === true) {
      const formData = {
        assetType: assetType,
        caseType: caseType,
        valuationPurpose: valuationPurpose,
        requesterID: requesterID,
        requesterName: requesterName,
        requesterBranch: requesterBranch,
        requesterPhone: requesterPhone,
        name: name,
        mobile: mobileNo,
        email: email,
        address: address,
        city: city,
        state: state,
        vehicleLocation: vehicleLocation,
        regdNo: vehicleRegdNo,
        brand: make,
        model: model,
        variant: variant,
        vehicleEdetails: {
          blacklistReason: vehicleData.blacklistReason,
          blacklistStatus: vehicleData.blacklistStatus,
          bodyType: vehicleData.bodyType,
          chassis: vehicleData.chassis,
          class: vehicleData.class,
          engine: vehicleData.engine,
          grossVehicleWeight: vehicleData.grossVehicleWeight,
          isCommercial: vehicleData.isCommercial,
          model: vehicleData.model,
          normsType: vehicleData.normsType,
          owner: vehicleData.owner,
          ownerCount: vehicleData.ownerCount,
          ownerFatherName: vehicleData.ownerFatherName,
          permanentAddress: vehicleData.permanentAddress,
          presentAddress: vehicleData.presentAddress,
          rcExpiryDate: vehicleData.rcExpiryDate,
          rcFinancer: vehicleData.rcFinancer,
          rcStandardCap: vehicleData.rcStandardCap,
          regAuthority: vehicleData.regAuthority,
          regDate: vehicleData.regDate,
          regNo: vehicleData.regNo,
          status: vehicleData.status,
          statusAsOn: vehicleData.statusAsOn,
          type: vehicleData.type,
          unladenWeight: vehicleData.unladenWeight,
          vehicleCategory: vehicleData.vehicleCategory,
          vehicleClass: vehicleData.vehicleClass,
          vehicleColour: vehicleData.vehicleColour,
          vehicleCubicCapacity: vehicleData.vehicleCubicCapacity,
          vehicleCylindersNo: vehicleData.vehicleCylindersNo,
          vehicleInsuranceCompanyName: vehicleData.vehicleInsuranceCompanyName,
          vehicleInsurancePolicyNumber:
            vehicleData.vehicleInsurancePolicyNumber,
          vehicleInsuranceUpto: vehicleData.vehicleInsuranceUpto,
          vehicleManufacturerName: vehicleData.vehicleManufacturerName,
          vehicleManufacturingMonthYear:
            vehicleData.vehicleManufacturingMonthYear,
          vehicleNumber: vehicleData.vehicleNumber,
          vehicleSeatCapacity: vehicleData.vehicleSeatCapacity,
          vehicleTaxUpto: vehicleData.vehicleTaxUpto,
          permitValidUpto: vehicleData.permitValidUpto,
          permitType: vehicleData.permitType,
          wheelbase: vehicleData.wheelbase,
        },
        fieldServiceBy: caseSubmitterName,
        revenue: revenue,
        cost: cost,
      };
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/cases/updatecase/${params.id}`;
      const resp = await axios.put(url, formData, config);
      if (resp.data.success) {
        navigate(`/admin/editreport2/${params.id}`);
      }
    } else {
      alert("Please enter vehicle regd no again");
    }
  };

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div
              className="flex items-center justify-between py-2 px-5"
              style={{
                backgroundColor: "#4D44B5",
                color: "#fff",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <h4
                className="text-lg font-medium text-default-950 capitalize"
                style={{ color: "#fff" }}
              >
                Add Case Details
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-3 gap-4">
                <div className="space-y-1">
                  <label
                    htmlFor="twoWheelerBatteryCondtion"
                    className="text-sm font-medium text-default-900"
                  >
                    Asset Type *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel
                      id="demo-simple-Asset Type"
                      className="inputcenter"
                    >
                      Asset Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Asset Type"
                      id="demo-simple-select"
                      value={assetType}
                      label="Asset Type"
                      onChange={(e) => selectAssetTypeChange(e)}
                      style={{ height: "35px" }}
                      inputProps={{ style: { textAlign: "center" } }}
                    >
                      {selectAssetType.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {assetType === "Commercial" ? (
                  <div className="space-y-1">
                    <label
                      htmlFor="twoWheelerBatteryCondtion"
                      className="text-sm font-medium text-default-900"
                    >
                      In Commercial Sub Asset Type *
                    </label>
                    <FormControl
                      fullWidth
                      style={{ marginTop: "12px" }}
                      className="textfield"
                    >
                      <InputLabel
                        id="demo-simple-Sub-Asset-Type"
                        className="inputcenter"
                      >
                        Sub Asset Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-Sub-Asset Type"
                        id="demo-simple-select"
                        value={commercialSubAssetType}
                        label="Asset Type"
                        onChange={(e) => selectCommercialAssetTypeChange(e)}
                        style={{ height: "35px" }}
                        inputProps={{ style: { textAlign: "center" } }}
                      >
                        {selectCommercialAssetType.map((test, index) => (
                          <MenuItem value={test.name} key={index}>
                            {test.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                ) : (
                  <></>
                )}
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                  >
                    Case Type *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel
                      id="demo-simple-Case-Type"
                      className="inputcenter"
                    >
                      Case Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Case-Type"
                      id="demo-simple-select"
                      value={caseType}
                      label="Case Type"
                      onChange={(e) => selectCaseChange(e)}
                      style={{ height: "35px" }}
                      inputProps={{ style: { textAlign: "center" } }}
                    >
                      {selectCaseType.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fValuation"
                    className="text-sm font-medium text-default-900"
                  >
                    Valuation Purpose *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Purpose Of Valuation"
                      variant="outlined"
                      className="textfield"
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={valuationPurpose}
                      onChange={(e) => setValuationPurposeType(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: "500", color: "#000066" }}
                  >
                    Case Submitted By <span style={{ color: "red" }}> **</span>
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel
                      id="demo-simple-caseSubmitterName"
                      className="inputcenter"
                    >
                      Case Submitted By
                    </InputLabel>
                    <Select
                      labelId="demo-simple-caseSubmitterName"
                      id="demo-simple-select"
                      value={caseSubmitterName}
                      label="caseSubmitterName"
                      onChange={
                        handleEmployeeChange
                      }
                      style={{ height: "38px" }}
                      inputProps={{ style: { textAlign: "center" } }}
                    >
                      {employeeList.map(
                        (test, index) =>
                          test.role === "businessAssociate" && (
                            <MenuItem
                              value={test.name}
                              key={index}
                              selected={test.name === caseSubmitterName}
                            >
                              {test.name}
                            </MenuItem>
                          )
                      )}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                  >
                    Case Submitted By *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Case Submitted By"
                      variant="outlined"
                      className={
                        "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={caseSubmitterName}
                      onChange={(e) => setCaseSubmitterName(e.target.value)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: "500", color: "#000066" }}
                  >
                    Valuation Requested By{" "}
                    <span style={{ color: "red" }}> **</span>
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel
                      id="demo-simple-requesterName"
                      className="inputcenter"
                    >
                      Valuation Requested By
                    </InputLabel>
                    <Select
                      labelId="demo-simple-requesterName"
                      id="demo-simple-select"
                      value={clientList.name}
                      label="requesterName"
                      onChange={handleChange}
                      style={{ height: "38px" }}
                      inputProps={{ style: { textAlign: "center" } }}
                    >
                      {clientList.map((test, index) => (
                        <MenuItem
                          value={test._id}
                          key={index}
                          selected={test.name === requesterName}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: "500", color: "#000066" }}
                  >
                    Valuation Requested By{" "}
                    <span style={{ color: "red" }}> **</span>
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Valuation Requested  By"
                      variant="outlined"
                      className={
                        "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={requesterName}
                      onChange={(e) => setRequesterName(e.target.value)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                  >
                    Requester Branch Name *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Requester Branch Name"
                      variant="outlined"
                      className={
                        requesterBranchError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={requesterBranch}
                      onChange={(e) => setRequesterBranch(e.target.value)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="lName"
                    className="text-sm font-medium text-default-900"
                  >
                    Requester Phone *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Requester Phone"
                      variant="outlined"
                      className={
                        requesterPhoneError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={requesterPhone}
                      onChange={(e) => handleRequesterPhn(e)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                  >
                    Customer Name *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Customer Name"
                      variant="outlined"
                      className="textfield"
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fMobile"
                    className="text-sm font-medium text-default-900"
                  >
                    Customer Mobile No *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Customer Phone"
                      variant="outlined"
                      className="textfield"
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={mobileNo}
                      onChange={(e) => setMobileNo(e)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fEmail"
                    className="text-sm font-medium text-default-900"
                  >
                    Customer Email *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Customer Email"
                      variant="outlined"
                      className="textfield"
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={email}
                      onChange={(e) => setEmail(e)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fAddress"
                    className="text-sm font-medium text-default-900"
                  >
                    Customer Address *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Customer Address"
                      variant="outlined"
                      className="textfield"
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={address}
                      onChange={(e) => setAddress(e)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fState"
                    className="text-sm font-medium text-default-900"
                  >
                    State *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="State"
                      variant="outlined"
                      className={"textfield"}
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fCity"
                    className="text-sm font-medium text-default-900"
                  >
                    City *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="City"
                      variant="outlined"
                      className={"textfield"}
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fVehicleLocation"
                    className="text-sm font-medium text-default-900"
                  >
                    Vehicle Location *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Vehicle Location"
                      variant="outlined"
                      className={"textfield"}
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={vehicleLocation}
                      onChange={(e) => setVehicleLocation(e.target.value)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fVehicleRegistration"
                    className="text-sm font-medium text-default-900"
                  >
                    Vehicle Registration No *
                  </label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    <div className="textfieldcontainer">
                      <TextField
                        label="Vehicle Registration No"
                        variant="outlined"
                        className={"textfield"}
                        type="text"
                        id="text"
                        value={vehicleRegdNo}
                        onChange={(e) => handleRegdNo(e)}
                      />
                    </div>
                    {dataFetched && vehicleData ? (
                      <>
                        <a href="/vehicleinfo" target="_blank">
                          <button
                            style={{
                              backgroundColor: "#009900",
                              color: "#fff",
                              borderRadius: "10px",
                              padding: "5px",
                              fontSize: "12px",
                            }}
                          >
                            View
                          </button>
                        </a>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fMake"
                    className="text-sm font-medium text-default-900"
                  >
                    Make (Vehicle Brand)*
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Make"
                      variant="outlined"
                      className={"textfield"}
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={make}
                      onChange={(e) => setMake(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fModel"
                    className="text-sm font-medium text-default-900"
                  >
                    Model (Vehicle Model)*
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Model"
                      variant="outlined"
                      className={"textfield"}
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={model}
                      onChange={(e) => setModel(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fVariant"
                    className="text-sm font-medium text-default-900"
                  >
                    Variant (Vehicle Variant)*
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Variant"
                      variant="outlined"
                      className={"textfield"}
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={variant}
                      onChange={(e) => setVariant(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex items-center gap-2 justify-end"
            style={{ marginBottom: "80px" }}
          >
            {/* <button
                            onClick={(e) => cancelClick(e)}
                            className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border border-transparent align-middle duration-500 text-sm text-center text-red-600 hover:border-red-500/20 hover:bg-red-500/20 rounded transition-all"
                        >
                            <i className="ph-bold ph-x text-lg" /> cancel
                        </button> */}
            {dataFetched && vehicleData ? (
              <>
                {caseSubmitterName && requesterName ? (
                  <button
                    className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                    style={{
                      background: "#4D44B5",
                      border: "none",
                      outline: "none",
                    }}
                    onClick={() => handleCaseUpdate()}
                  >
                    <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                    Next
                  </button>
                ) : (
                  <button
                    className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                    style={{
                      background: "#555",
                      border: "none",
                      outline: "none",
                    }}
                    onClick={() => handleDetails()}
                  >
                    <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                    Next
                  </button>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditReport;
