import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminPost, valiadteEmail, valiadteNumber } from "../../redux/Authentication/AuthSlice";
import { createEmployee } from "../../redux/Employee/employeeSlice";
import { FileUploader } from "react-drag-drop-files";
import { CaseActions, UploadChasisImage, UploadFrontImage, UploadOpenBonnet, UploadRearUnderPart, UploadRight45Image, createCase, selfieWithVehicle, selfieWithVehicleUpload, sendVehicleNumber, uploadAnyDamage, uploadExtraImage, uploadExtraImage1, uploadExtraImage2, uploadExtraImage3, uploadFrontUnderCar, uploadGlassInsideImage, uploadGlassOutside, uploadLeft45, uploadLeftImage, uploadOdometerImage, uploadOpenDickey, uploadRcBackImage, uploadRcFront, uploadRearImage, uploadRightImage, uploadRpmImage, uploadSignature, uploadTyreImage, uploadformatImage } from "../../redux/Cases/CaseSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import FourWheelerEditReport4 from "../Reports/FourWheelerEditReport4";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { TextField } from "@mui/material";
import ImageUpload from "./UploadImage";

const AddCommercialCase = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate()
    const { totalClients } = useSelector((store) => store.client);
    const { selfieWithVehicleImage, isSelfieWithAgentLoading, chasisImage, isChasisImageLoading,
        IsformatImageLoading,
        formatImage,
        isOpenBonnetLoading,
        openBonnet,
        isRight45ImageLoading,
        right45image,
        frontimage,
        isFrontImageLoading,
        frontUnderCarImage,
        isFrontUnderCarImageLoading,
        isLeft45Loading,
        left45image,
        isLeftImageLoading,
        leftImage,
        rearImage,
        isRearImageLoading,
        rearUnderPartImage,
        isRearUnderPartLoading,
        openDickeyImage,
        isOpenDickeyImageLoading,
        rightImage,
        isRightImageLoading,
        tyreImage,
        isTyreImageLoading,
        glassOutsideImage,
        isGlassOutsideImageLoading,
        glassInsideImage,
        isGlassInsideImageLoading,
        odometerImage,
        isOdometerImageLoading,
        rpmImage,
        isRpmImageLoading,
        anyDamage,
        isAnyDamageLoading,
        extraImage,
        isExtraImageLoading,
        extraImage1,
        isExtraImage1Loading,
        extraImage2,
        isExtraImage2Loading,
        extraImage3,
        isExtraImage3Loading,
        signatureImage,
        isSignatureImageLoading,
        rcFrontImage,
        isRcFrontImageLoading,
        rcBackImage,
        isRcBackImageLoading
    } = useSelector((store) => store.cases)
    const [assetType, setAssetType] = useState("");
    const [assetTypeError, setAssetTypeError] = useState("");
    const [caseType, setCaseType] = useState("");
    const [caseTypeError, setCaseTypeError] = useState("");
    const [valuationPurpose, setValuationPurpose] = useState('Assesment of fair market value');
    const [valuationPurposeError, setValuationPurposeError] = useState('');
    const [requesterID, setRequesterID] = useState("");
    const [requesterName, setRequesterName] = useState("");
    const [requesterNameError, setRequesterNameError] = useState("");
    const [requesterBranch, setRequesterBranch] = useState("");
    const [requesterBranchError, setRequesterBranchError] = useState("");
    const [requesterPhone, setRequesterPhone] = useState("");
    const [requesterPhoneError, setRequesterPhoneError] = useState("");
    const [email, setEmail] = useState("");
    const [Cnfemail, setCnfEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [mobile, setMobile] = useState("");
    const [mobileCnf, setMobileCnf] = useState("");
    const [mobileError, setMobileError] = useState("");
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [address, setAddress] = useState("")
    const [addressError, setAddressError] = useState("")
    const [state, setState] = useState("")
    const [stateError, setStateError] = useState("")
    const [cityList, setCityList] = useState([]);
    const [city, setCity] = useState("")
    const [cityError, setCityError] = useState("")
    const [productType, setProductType] = useState("")
    const [productTypeError, setProductTypeError] = useState("")
    const [productBrand, setProductBrand] = useState("")
    const [productBrandError, setProductBrandError] = useState("")
    const [vehicleLocation, setVehicleLocation] = useState('')
    const [vehicleLocationError, setVehicleLocationError] = useState('')
    const [model, setModel] = useState("")
    const [ModelError, setModelError] = useState("")
    const [variant, setVariant] = useState("")
    const [variantError, setVariantError] = useState("")
    const [loanNo, setLoanNo] = useState("")
    const [loanNoError, setLoanNoError] = useState("")
    const [docVerified, setDocVerified] = useState('');
    const [regdOwner, setRegdOwner] = useState("")
    const [regdOwnerError, setRegdOwnerError] = useState("")
    const [regdDate, setRegdDate] = useState("")
    const [regdDateError, setRegdDateError] = useState("")
    const [mfgDate, setMfgdate] = useState('');
    const [numberPlateType, setNumberPlateType] = useState('');
    const [regdNo, setRegdNo] = useState("")
    const [regdNoError, setRegdNoError] = useState("")
    const [vehicleData, setVehicleData] = useState({});
    const [dataFetched, setDataFetched] = useState(false)
    const [engNo, setEngNo] = useState("")
    const [engNoError, setEngNoError] = useState("")
    const [chasisNo, setChasisNo] = useState("")
    const [chasisNoError, setChasisNoError] = useState("")
    const [bodyType, setBodyType] = useState("")
    const [bodyTypeError, setBodyTypeError] = useState("")
    const [meterReading, setMeterReading] = useState('');
    const [fueltype, setFueltype] = useState("")
    const [fueltypeError, setFueltypeError] = useState("")
    const [vehicleColor, setVehicleColor] = useState("")
    const [vehicleColorError, setVehicleColorError] = useState("")
    const [ownerSerialNo, setOwnerSerialNo] = useState("")
    const [ownerSerialNoError, setOwnerSerialNoError] = useState("")
    const [hypothecationBank, setHypothecationBank] = useState("")
    const [hypothecationBankError, setHypothecationBankError] = useState("")
    const [insuranceCompany, setInsuranceCompany] = useState('');
    const [insuranceStatus, setInsuranceStatus] = useState('');
    const [cubicCapacity, setCubicCapacity] = useState("")
    const [cubicCapacityError, setCubicCapacityError] = useState("")
    const [seatingCapacity, setSeatingCapacity] = useState("")
    const [eatingCapacityError, setSeatingCapacityError] = useState("")
    const [vehicleWeight, setVehicleWeight] = useState("")
    const [vehicleWeightError, setVehicleWeightError] = useState("")

    const [selectedVideo, setSelectedVideo] = useState(null);
    const [showLoader, setShowLoader] = useState(false)
    const [videoRecord, setVideoRecord] = useState('')

    const [role, setRole] = useState("")
    const fileTypes = ["JPG", "PNG", "GIF"];

    const [airConditioner, setAirConditioner] = useState("");
    const [cdCharge, setCdCharge] = useState("");
    const [seatCover, setSeatCover] = useState("");
    const [fogLamps, setFogLamps] = useState("");
    const [transmission, setTransmission] = useState("");
    const [odometer, setOdometer] = useState("");
    const [accidental, setAccidental] = useState("");
    const [otherRepair, setotherRepair] = useState("");
    const [fitnessExpiry, setFitnessExpiry] = useState("");
    const [roadtaxValidity, setroadtaxValidity] = useState("");
    const [idvValue, setIdvValue] = useState("");
    const [insuranceExpiry, setInsuranceExpiry] = useState("");
    const [engineCondition, setEngineCondition] = useState("");
    const [engineConditionError, setEngineConditionError] = useState("");
    const [wheelType, setWheelType] = useState("");
    const [totalTyre, setTotalTyre] = useState("");
    const [availableTyre, setAvailableTyre] = useState("");
    const [missingTyre, setMissingTyre] = useState("");
    const [spareTyre, setSpareTyre] = useState("");
    const [interiorCondition, setInteriorCondition] = useState("");
    const [interiorConditionError, setInteriorConditionError] = useState("");
    const [exteriorCondition, setExteriorCondition] = useState("");
    const [exteriorConditionError, setExteriorConditionError] = useState("");
    const [headLamp, setHeadLamp] = useState("");
    const [tailLamp, setTailLamp] = useState("");
    const [frontIndicators, setFrontIndicators] = useState("");
    const [rearIndicators, setRearIndicators] = useState("");
    const [maintenanceLevel, setMaintenanceLevel] = useState("");
    const [frontBrakeCondition, setFrontBreakCondition] = useState("");
    const [frontBrakeConditionError, setFrontBreakConditionError] = useState("");
    const [rearBrakeCondition, setRearBreakCondition] = useState("");
    const [rearBrakeConditionError, setRearBreakConditionError] = useState("");
    const [comFrontCrashGuard, setComFrontCrashGuard] = useState("");
    const [comLoadCarrier, setComLoadCarrier] = useState("");
    const [comMusicSystem, setComMusicSystem] = useState("");
    const [comRearUnderRun, setComRearUnderRun] = useState("");
    const [comSideUnderRun, setComSideUnderRun] = useState("");
    const [comToolkit, setComToolkit] = useState("");
    const [comTvVideo, setComTvVideo] = useState("");
    const [comBodyCondition, setComBodyCondition] = useState("");
    const [comHandBrake, setComHandBrake] = useState("");
    const [comDoorCondition, setComDoorCondition] = useState("");
    const [comSideGlassesCondition, setComSideGlassesCondition] = useState("");
    const [comWindscreenCondition, setComWindscreenCondition] = useState("");
    const [comBatteryCondition, setComBatteryCondition] = useState("");
    const [comClusterUnitCondition, setComClusterUnitCondition] = useState("");
    const [comFogLampStatus, setComFogLampStatus] = useState("");
    const [comBatteryNo, setComBatteryNo] = useState(null);
    const [comWiperStatus, setComWiperStatus] = useState("");
    const [comEngineStatus, setComEngineStatus] = useState("");
    const [comEngineShieldCondition, setComEngineShieldCondition] = useState("");
    const [comFluidLeakStatus, setComFluidLeakStatus] = useState("");
    const [comSuspensionCondition, setComSuspensionCondition] = useState("");
    const [comSuspensionConditionError, setComSuspensionConditionError] = useState("");
    const [comTransmissionCondition, setComTransmissionCondition] = useState("");
    const [comTransmissionConditionError, setComTransmissionConditionError] = useState("");
    const [comAxleOneLeftIn, setComAxleOneLeftIn] = useState("");
    const [comAxleOneLeftOut, setComAxleOneLeftOut] = useState("");
    const [comAxleOneRightIn, setComAxleOneRightIn] = useState("");
    const [comAxleOneRightOut, setComAxleOneRightOut] = useState("");
    const [comAxleThreeLeftIn, setComAxleThreeLeftIn] = useState("");
    const [comAxleThreeLeftOut, setComAxleThreeLeftOut] = useState("");
    const [comAxleThreeRightIn, setComAxleThreeRightIn] = useState("");
    const [comAxleThreeRightOut, setComAxleThreeRightOut] = useState("");
    const [comAxleTwoLeftIn, setComAxleTwoLeftIn] = useState("");
    const [comAxleTwoLeftOut, setComAxleTwoLeftOut] = useState("");
    const [comAxleTwoRightIn, setComAxleTwoRightIn] = useState("");
    const [comAxleTwoRightOut, setComAxleTwoRightOut] = useState("");
    const [comFrontOneLeft, setComFrontOneLeft] = useState("");
    const [comFrontOneRight, setComFrontOneRight] = useState("");
    const [comFrontTwoLeft, setComFrontTwoLeft] = useState("");
    const [comFrontTwoRight, setComFrontTwoRight] = useState("");
    const [comFrontTruckTyreCondition, setComFrontTruckTyreCondition] =
        useState("");
    const [comFrontTruckTyreConditionError, setComFrontTruckTyreConditionError] =
        useState("");
    const [comFrontTruckTyreType, setComFrontTruckTyreType] = useState("");
    const [comAxleCondition, setComAxleCondition] = useState("");

    const [yourRemark, setYourRemark] = useState("");
    const [exShowRoomPrice, setExShowRoomPrice] = useState("");
    const [exShowRoomPriceError, setExShowRoomPriceError] = useState("");
    const [estimatedPrice, setEstimatedPrice] = useState("");
    const [estimatedPriceError, setEstimatedPriceError] = useState("");

    const [buttonLoading, setButtonLoading] = useState(false)

    const [clientList, setClientList] = useState([])
    useEffect(() => {
        const client = totalClients.map((indClient, index) => ({
            id: index,
            _id: indClient._id,
            name: indClient.name,
            phone: indClient.phone,
            organisation: indClient.organisation
        }));
        setClientList(client);
    }, [totalClients])
    const selectCaseType = [
        {
            id: 1,
            name: "Revaluation",
        },
        {
            id: 2,
            name: "Repossession",
        },
        {
            id: 3,
            name: "Retail",
        },
        {
            id: 4,
            name: "Insurance Idv",
        },
        {
            id: 5,
            name: "Repurchase",
        },
    ];
    const selectCaseChange = (e) => {
        const { value } = e.target;
        setCaseType(value);
    };
    const selectAssetType = [
        {
            id: 1,
            name: "Two Wheeler",
        },
        {
            id: 2,
            name: "Four Wheeler",
        },
        {
            id: 3,
            name: "Commercial",
        },
        {
            id: 4,
            name: "Construction Equipment",
        },
        {
            id: 5,
            name: "Three Wheeler",
        },
        {
            id: 6,
            name: "Farm Equipment",
        },
    ];
    const handleRequester = (e) => {
        const { organisation, phone, _id } = e.target.value
        setRequesterID(_id)
        setRequesterPhone(phone)
    }
    const selectAssetChange = (e) => {
        const { value } = e.target;
        setAssetType(value);
    };

    const handleRequesterPhn = async (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setRequesterPhone(value)
    }

    const stateList = [
        { id: 1, name: "Andhra Pradesh" },
        { id: 2, name: "Arunachal Pradesh" },
        { id: 3, name: "Assam" },
        { id: 4, name: "Bihar" },
        { id: 5, name: "Chhattisgarh" },
        { id: 6, name: "Goa" },
        { id: 7, name: "Gujarat" },
        { id: 8, name: "Haryana" },
        { id: 9, name: "Himachal Pradesh" },
        { id: 10, name: "Jharkhand" },
        { id: 11, name: "Karnataka" },
        { id: 12, name: "Kerala" },
        { id: 13, name: "Madhya Pradesh" },
        { id: 14, name: "Maharashtra" },
        { id: 15, name: "Manipur" },
        { id: 16, name: "Meghalaya" },
        { id: 17, name: "Mizoram" },
        { id: 18, name: "Nagaland" },
        { id: 19, name: "Odisha" },
        { id: 20, name: "Punjab" },
        { id: 21, name: "Rajasthan" },
        { id: 22, name: "Sikkim" },
        { id: 23, name: "Tamil Nadu" },
        { id: 24, name: "Telangana" },
        { id: 25, name: "Tripura" },
        { id: 26, name: "Uttar Pradesh" },
        { id: 27, name: "Uttarakhand" },
        { id: 28, name: "West Bengal" },
        { id: 29, name: "Andaman and Nicobar Islands" },
        { id: 30, name: "Chandigarh" },
        { id: 31, name: "Dadra and Nagar Haveli and Daman and Diu" },
        { id: 32, name: "Delhi" },
        { id: 33, name: "Ladakh" },
        { id: 34, name: "Lakshadweep" },
        { id: 35, name: "Puducherry" }
    ];
    const selectStateChange = (e) => {
        const { value } = e.target;
        setState(value);
    };
    useEffect(() => {
        if (state) {
            const formData = {
                country: 'India',
                state: state.toLocaleUpperCase(),
            };
            const getCity = async () => {
                try {
                    const config = {
                        headers: { 'Content-Type': 'application/json' },
                    };
                    const url = 'https://countriesnow.space/api/v0.1/countries/state/cities';
                    const resp = await axios.post(url, formData, config);
                    if (!resp.data.error) {
                        const citiesData = resp.data.data.map((city, index) => ({
                            id: index + 1,
                            name: city.toLowerCase().replace(/\s/g, '_'),
                        }));
                        setCityList(citiesData);
                    } else {
                        console.error("Error in response:", resp.data.error); // Log the error for debugging
                    }
                } catch (error) {
                    console.error("Error:", error); // Log any other errors for debugging
                }
            };

            getCity();
        }
    }, [state]);

    const selectCityChange = (e) => {
        const { value } = e.target;
        setCity(value);
    };

    const handleRegdNo = async (e) => {
        const inputValue = e.target.value.toUpperCase();
        setRegdNo(inputValue);
        setDataFetched(false); // Reset data fetched status

        if (inputValue.length === 9 || inputValue.length === 10) {
            try {
                // let newData = await fetchVehicleData(inputValue);
                const formData = {
                    "regdNo": inputValue,
                }
                const vehicle = await dispatch(sendVehicleNumber(formData))
                setVehicleData(vehicle.payload.response.result);
                setDataFetched(true);
            } catch (error) {
                setVehicleData(null); // Reset vehicle data
            }
        } else {
            setVehicleData(null); // Reset vehicle data if input length is invalid
        }
    };

    useEffect(() => {
        // Fetch data again if required properties are null after initial fetch
        if (dataFetched && vehicleData) {
            const {
                rcFinancer,
                vehicleTaxUpto,
                permitValidUpto,
                permitType,
                vehicleSeatCapacity,
                grossVehicleWeight,
                regAuthority
            } = vehicleData;

            if (
                !rcFinancer ||
                !vehicleTaxUpto ||
                !permitValidUpto ||
                !permitType ||
                !vehicleSeatCapacity ||
                !grossVehicleWeight ||
                !regAuthority
            ) {
                handleRegdNo({ target: { value: regdNo } });
            }
        }
    }, [dataFetched, vehicleData, regdNo]);

    const documents = [
        { name: 'Verified', id: 1 },
        { name: 'Not Verified', id: 2 },
    ];
    const selectDocuementVerifiedTypeChange = (e) => {
        const { value } = e.target;
        setDocVerified(value);
    };
    const number = [
        { name: 'White', id: 1 },
        { name: 'Yellow', id: 2 },
        { name: 'Blue', id: 3 },
        { name: 'Black', id: 4 },
    ];
    const selectNumberPlateTypeChange = (e) => {
        const { value } = e.target;
        setNumberPlateType(value);
    };
    const options = [
        { name: 'Yes', id: 1 },
        { name: 'No', id: 2 },
    ];
    const Features = [
        { name: "Manual", id: 1 },
        { name: "Automatic", id: 2 },
    ];
    const Odometer = [
        { name: "Digital", id: 1 },
        { name: "Normal", id: 2 },
    ];
    const condition = [
        { name: "Poor", id: 1 },
        { name: "Average", id: 2 },
        { name: "Satisfactory", id: 3 },
        { name: "Good", id: 4 },
        { name: "Excellent", id: 5 },
        { name: "NA", id: 6 },
    ];
    const reqCondition = [
        { name: "Poor", id: 1 },
        { name: "Average", id: 2 },
        { name: "Satisfactory", id: 3 },
        { name: "Good", id: 4 },
        { name: "Excellent", id: 5 }
    ];
    const list = [
        { name: "10", id: 1 },
        { name: "20", id: 2 },
        { name: "30", id: 3 },
        { name: "40", id: 4 },
        { name: "50", id: 5 },
        { name: "60", id: 6 },
        { name: "70", id: 7 },
        { name: "80", id: 8 },
        { name: "90", id: 9 },
        { name: "100", id: 10 },
    ];
    const qualityList = [
        { name: "Original", id: 1 },
        { name: "Replaced", id: 2 },
    ];
    const wheelTypeList = [
        { name: "Alloy", id: 1 },
        { name: "Steel", value: 2 },
        { name: "Rim", value: 3 },
    ];


    const selectInsuranceStatusChange = (e) => {
        const { value } = e.target;
        setInsuranceStatus(value);
    };

    const handleFileChange = (event) => {
        setSelectedVideo(event.target.files[0])
    };

    const handleUpload = async () => {
        setShowLoader(true);
        if (selectedVideo) {
            const formData = new FormData();
            formData.append("file", selectedVideo);
            try {
                const res = await axios.post(`${Baseurl}/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setVideoRecord(res.data.filePath);
                document.getElementById("videouploading").value = "";
                setShowLoader(false);
                alert('file uploaded')
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            console.log("No video selected.");
        }
    };

    const handleSelfieWithVehicleChange = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        await dispatch(selfieWithVehicleUpload(formData));
    };
    const handleChasisChange = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        dispatch(UploadChasisImage(formData));
    };
    const handleFormatChange = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        dispatch(uploadformatImage(formData));
    };
    const handleOpenBonnetChange = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(UploadOpenBonnet(formData));
    };
    const handleright45Change = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        dispatch(UploadRight45Image(formData));
    };
    const frontImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(UploadFrontImage(formData));
    };
    const frontUnderCarImg = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadFrontUnderCar(formData));
    };
    const handlesetLeft45Image = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadLeft45(formData));
    };
    const handlesetLeftImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadLeftImage(formData));
    };
    const handlesetRearImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadRearImage(formData));
    };
    const handlerearUnderPartImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(UploadRearUnderPart(formData));
    };
    const handlerearOpenDickeyImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadOpenDickey(formData));
    };
    const HandlerightImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadRightImage(formData));
    };
    const handlesetTyreImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadTyreImage(formData));
    };
    const handleGlassOutsideImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadGlassOutside(formData));
    };
    const handleGlassInsideImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadGlassInsideImage(formData));
    };
    const handleOdometerImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadOdometerImage(formData));
    };
    const handleRpmImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadRpmImage(formData));
    };
    const handleAnyDamage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadAnyDamage(formData));
    };
    const handleExtraImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadExtraImage(formData));
    };
    const handleExtraImage1 = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadExtraImage1(formData));
    };
    const handleExtraImage2 = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadExtraImage2(formData));
    };
    const handleExtraImage3 = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadExtraImage3(formData));
    };
    const handleSignature = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadSignature(formData));
    };
    const handleFrontRc = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadRcFront(formData));
    };
    const handleBackRc = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadRcBackImage(formData));
    };


    const selectAirConditionerChange = (e) => {
        const { value } = e.target;
        setAirConditioner(value);
    };
    const selectCdChargeChange = (e) => {
        const { value } = e.target;
        setCdCharge(value);
    };
    const selectSeatCoverChange = (e) => {
        const { value } = e.target;
        setSeatCover(value);
    };
    const selectFogLampsChange = (e) => {
        const { value } = e.target;
        setFogLamps(value);
    };
    const selectTransmissionChange = (e) => {
        const { value } = e.target;
        setTransmission(value);
    };
    const selectOdometerChange = (e) => {
        const { value } = e.target;
        setOdometer(value);
    };
    const selectAccidentalChange = (e) => {
        const { value } = e.target;
        setAccidental(value);
    };
    const selectotherRepairlChange = (e) => {
        const { value } = e.target;
        setotherRepair(value);
    };
    const selectEngineConditionChange = (e) => {
        const { value } = e.target;
        setEngineCondition(value);
    };
    const selectWheelTypeChange = (e) => {
        const { value } = e.target;
        setWheelType(value);
    };
    const selectInteriorConditionChange = (e) => {
        const { value } = e.target;
        setInteriorCondition(value);
    };
    const selectExteriorConditionChange = (e) => {
        const { value } = e.target;
        setExteriorCondition(value);
    };
    const selectHeadLampChange = (e) => {
        const { value } = e.target;
        setHeadLamp(value);
    };
    const selectTailLampChange = (e) => {
        const { value } = e.target;
        setTailLamp(value);
    };
    const selectFrontIndicatorsChange = (e) => {
        const { value } = e.target;
        setFrontIndicators(value);
    };
    const selectRearIndicatorsChange = (e) => {
        const { value } = e.target;
        setRearIndicators(value);
    };
    const selectMaintenanceLevelChange = (e) => {
        const { value } = e.target;
        setMaintenanceLevel(value);
    };
    const selectFrontBreakConditionChange = (e) => {
        const { value } = e.target;
        setFrontBreakCondition(value);
    };
    const selectRearBreakConditionChange = (e) => {
        const { value } = e.target;
        setRearBreakCondition(value);
    };
    const selectsetFrontCrashGuardChange = (e) => {
        const { value } = e.target;
        setComFrontCrashGuard(value);
    };
    const selectComLoadCarrierChange = (e) => {
        const { value } = e.target;
        setComLoadCarrier(value);
    };
    const selectComMusicSystemChange = (e) => {
        const { value } = e.target;
        setComMusicSystem(value);
    };
    const selectComRearUnderRunChange = (e) => {
        const { value } = e.target;
        setComRearUnderRun(value);
    };
    const selectComSideUnderRunChange = (e) => {
        const { value } = e.target;
        setComSideUnderRun(value);
    };
    const selectComToolkitChange = (e) => {
        const { value } = e.target;
        setComToolkit(value);
    };
    const selectComTvVideoChange = (e) => {
        const { value } = e.target;
        setComTvVideo(value);
    };
    const selectComBodyConditionChange = (e) => {
        const { value } = e.target;
        setComBodyCondition(value);
    };
    const selectComHandBrakeChange = (e) => {
        const { value } = e.target;
        setComHandBrake(value);
    };
    const selectComDoorConditionChange = (e) => {
        const { value } = e.target;
        setComDoorCondition(value);
    };
    const selectComSideGlassesConditionChange = (e) => {
        const { value } = e.target;
        setComSideGlassesCondition(value);
    };
    const selectComWindscreenConditionChange = (e) => {
        const { value } = e.target;
        setComWindscreenCondition(value);
    };
    const selectComBatteryConditionChange = (e) => {
        const { value } = e.target;
        setComBatteryCondition(value);
    };
    const selectComClusterUnitConditionChange = (e) => {
        const { value } = e.target;
        setComClusterUnitCondition(value);
    };
    const selectComFogLampStatusChange = (e) => {
        const { value } = e.target;
        setComFogLampStatus(value);
    };
    const selectComWiperStatusChange = (e) => {
        const { value } = e.target;
        setComWiperStatus(value);
    };
    const selectComEngineStatusChange = (e) => {
        const { value } = e.target;
        setComEngineStatus(value);
    };
    const selectComEngineShieldConditionChange = (e) => {
        const { value } = e.target;
        setComEngineShieldCondition(value);
    };
    const selectComFluidLeakStatusChange = (e) => {
        const { value } = e.target;
        setComFluidLeakStatus(value);
    };
    const selectComSuspensionConditionChange = (e) => {
        const { value } = e.target;
        setComSuspensionCondition(value);
    };
    const selectComTransmissionConditionChange = (e) => {
        const { value } = e.target;
        setComTransmissionCondition(value);
    };
    const selectComAxleOneLeftInChange = (e) => {
        const { value } = e.target;
        setComAxleOneLeftIn(value);
    };
    const selectComAxleOneLeftOutChange = (e) => {
        const { value } = e.target;
        setComAxleOneLeftOut(value);
    };
    const selectComAxleOneRightInChange = (e) => {
        const { value } = e.target;
        setComAxleOneRightIn(value);
    };
    const selectComAxleOneRightOutChange = (e) => {
        const { value } = e.target;
        setComAxleOneRightOut(value);
    };
    const selectComAxleThreeLeftInChange = (e) => {
        const { value } = e.target;
        setComAxleThreeLeftIn(value);
    };
    const selectComAxleThreeLeftOutChange = (e) => {
        const { value } = e.target;
        setComAxleThreeLeftOut(value);
    };
    const selectComAxleThreeRightInChange = (e) => {
        const { value } = e.target;
        setComAxleThreeRightIn(value);
    };
    const selectComAxleThreeRightOutChange = (e) => {
        const { value } = e.target;
        setComAxleThreeRightOut(value);
    };
    const selectComAxleTwoLeftInChange = (e) => {
        const { value } = e.target;
        setComAxleTwoLeftIn(value);
    };
    const selectComAxleTwoLeftOutChange = (e) => {
        const { value } = e.target;
        setComAxleTwoLeftOut(value);
    };
    const selectComAxleTwoRightInChange = (e) => {
        const { value } = e.target;
        setComAxleTwoRightIn(value);
    };
    const selectComAxleTwoRightOutChange = (e) => {
        const { value } = e.target;
        setComAxleTwoRightOut(value);
    };
    const selectComFrontOneLeftChange = (e) => {
        const { value } = e.target;
        setComFrontOneLeft(value);
    };
    const selectComFrontOneRightChange = (e) => {
        const { value } = e.target;
        setComFrontOneRight(value);
    };
    const selectComFrontTwoLeftChange = (e) => {
        const { value } = e.target;
        setComFrontTwoLeft(value);
    };
    const selectComFrontTwoRightChange = (e) => {
        const { value } = e.target;
        setComFrontTwoRight(value);
    };
    const selectComFrontTruckTyreConditionChange = (e) => {
        const { value } = e.target;
        setComFrontTruckTyreCondition(value);
    };
    const selectComFrontTruckTyreTypeChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setComFrontTruckTyreType(text);
    };
    const selectComAxleConditionChange = (e) => {
        const { value } = e.target;
        setComAxleCondition(value);
    };
    const selectYourRemarkChange = (e) => {
        const { value } = e.target;
        setYourRemark(value);
    };


    const [btnPress, setbtnPress] = useState(false);

    useEffect(() => {
        if (btnPress === true) {
            if (name === "") {
                setNameError("please enter title");
            } else {
                setNameError("");
            }
            if (Cnfemail === "") {
                setEmailError("please enter email");
            } else {
                setEmailError("");
            }
            if (mobileCnf.length !== 10) {
                setMobileError("please enter mobile");
            } else {
                setMobileError("");
            }
        }
    }, [btnPress, name, Cnfemail, mobileCnf]);

    const setNumberChange = async (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setMobileError("");
        setMobile(value);
        if (value.length === 10) {
            // const verifyNumber = await dispatch(valiadteNumber(value));
            // if (verifyNumber.payload.success) {
            //     setMobileError("Number already exist");
            // } else {
            // }
            setMobileError("");
            setMobileCnf(value);
        } else {
            setMobileError("Please Enter Valid Number");
        }
    };

    const emailChange = async (e) => {
        const value = e;
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        setEmail(value);
        setEmailError("");
        if (value.length > 0) {
            if (value.match(mailformat)) {
                // const verifyEmail = await dispatch(valiadteEmail(value));
                // if (verifyEmail.payload.success) {
                //     setEmailError("Number already exist");
                // } else {
                // }
                setEmailError("");
                setCnfEmail(value);
            } else {
                setEmailError("please enter correct Email format");
            }
        } else {
            setEmailError("please enter correct Email format");
        }
    };

    const cancelClick = async (e) => {
        e.preventDefault();
        setEmail("");
        setCnfEmail("");
        setMobile("");
        setMobileCnf("");
        setName("");
    };

    const handlesubmit = async (e) => {
        e.preventDefault();
        setButtonLoading(true)
        if (name === "") {
            setNameError("please enter title");
        } else {
            setNameError("");
        }
        if (Cnfemail === "") {
            setEmailError("please enter email");
        } else {
            setEmailError("");
        }
        if (mobileCnf.length !== 10) {
            setMobileError("please enter mobile");
        } else {
            setMobileError("");
        }
        if (name !== "" && Cnfemail !== "" && mobileCnf !== "") {
            const formData = {
                assetType: assetType,
                caseType: caseType,
                valuationPurpose: valuationPurpose,
                vehicleLocation: vehicleLocation,
                requesterID: requesterID,
                requesterName: requesterName,
                requesterBranch: requesterBranch,
                requesterPhone: requesterPhone,
                name: name,
                mobile: mobileCnf,
                email: Cnfemail,
                address: address,
                state: state,
                city: city,
                regdNo: regdNo,
                productType: productType,
                brand: productBrand,
                model: model,
                variant: variant,
                loanAgreementNo: loanNo,
                documentVerification: docVerified,
                regdOwner: regdOwner,
                regdDate: regdDate,
                numberPlate: numberPlateType,
                engNo: engNo,
                chasisNo: chasisNo,
                bodyType: bodyType,
                manufacturingDate: mfgDate,
                fueltype: fueltype,
                vehicleColor: vehicleColor,
                ownerSerialNo: ownerSerialNo,
                hypothecationBank: hypothecationBank,
                cubicCapacity: cubicCapacity,
                seatingCapacity: seatingCapacity,
                vehicleWeight: vehicleWeight,
                videos: videoRecord,
                images: {
                    selfieWithVehicle: selfieWithVehicleImage,
                    chasisImage: chasisImage,
                    format: formatImage,
                    openBonnet: openBonnet,
                    right45: right45image,
                    frontImage: frontimage,
                    frontUnderCar: frontUnderCarImage,
                    left45: left45image,
                    leftImage: leftImage,
                    rearImage: rearImage,
                    rearUnderPart: rearUnderPartImage,
                    openDickey: openDickeyImage,
                    rightImage: rightImage,
                    tyreImage: tyreImage,
                    glassOutside: glassOutsideImage,
                    glassInside: glassInsideImage,
                    odometerImage: odometerImage,
                    rpmImage: rpmImage,
                    anyDamage: anyDamage,
                    extraImage: extraImage,
                    extraImage1: extraImage1,
                    extraImage2: extraImage2,
                    extraImage3: extraImage3,
                },
                signNdoc: {
                    sign: signatureImage,
                    rcFront: rcFrontImage,
                    rcBack: rcBackImage,
                },
                accessories: {
                    ac: airConditioner,
                    cdCharger: cdCharge,
                    seatCover: seatCover,
                    fogLamps: fogLamps,
                },
                additionalFeatures: {
                    transmission: transmission,
                    odometer: odometer,
                },
                otherDetails: {
                    accidental: accidental,
                    otherRepair: otherRepair,
                    fitnessExpiry: fitnessExpiry,
                    roadTaxValidity: roadtaxValidity,
                    IDVValue: idvValue,
                    insuranceExpiry: insuranceExpiry,
                    engineCondition: engineCondition,
                    frontBrakeCondition: frontBrakeCondition,
                    rearBrakeCondition: rearBrakeCondition,
                    wheelsType: wheelType,
                    totalTyre: Number(totalTyre),
                    availableTyre: Number(availableTyre),
                    missingTyre: Number(missingTyre),
                    spareTyre: Number(spareTyre),
                    interiorCondition: interiorCondition,
                    exteriorCondition: exteriorCondition,
                    headLamp: headLamp,
                    tailLamp: tailLamp,
                    frontIndicators: frontIndicators,
                    rearIndicators: rearIndicators,
                    levelOfMaintenance: maintenanceLevel,
                },
                commercial: {
                    engine: {
                        engine: comEngineStatus,
                        fluidLeak: comFluidLeakStatus,
                        engineShield: comEngineShieldCondition,
                    },
                    transmission: {
                        transmissionCondition: comTransmissionCondition,
                    },
                    suspension: {
                        suspensionCondition: comSuspensionCondition,
                    },
                    brakes: {
                        handBrake: comHandBrake,
                    },
                    tyresNConditions: {
                        tyreType: comFrontTruckTyreType,
                        truckTyreCondition: comFrontTruckTyreCondition,
                        axlesCondition: comAxleCondition,
                        frontOneLeft: comFrontOneLeft,
                        frontTwoLeft: comFrontTwoLeft,
                        axleOneLeftIn: comAxleOneLeftIn,
                        axleOneRightIn: comAxleOneRightIn,
                        axleTwoLeftIn: comAxleTwoLeftIn,
                        axleTwoRightIn: comAxleTwoRightIn,
                        axleThreeLeftIn: comAxleThreeLeftIn,
                        axleThreeRightIn: comAxleThreeRightIn,
                        frontOneRight: comFrontOneRight,
                        frontTwoRight: comFrontTwoRight,
                        axleOneLeftOut: comAxleOneRightIn,
                        axleOneRightOut: comAxleOneRightOut,
                        axleTwoLeftOut: comAxleTwoRightIn,
                        axleTwoRightOut: comAxleTwoRightOut,
                        axleThreeLeftOut: comAxleThreeLeftOut,
                        axleThreeRightOut: comAxleThreeRightOut,
                    },
                    cabinNinterior: {
                        windScreen: comWindscreenCondition,
                        sideGlasses: comSideGlassesCondition,
                        doors: comDoorCondition,
                    },
                    electricals: {
                        noOfBatteries: Number(comBatteryNo),
                        batteryCondition: comBatteryCondition,
                        clusterUnit: comClusterUnitCondition,
                        fogLamps: comFogLampStatus,
                        wiper: comWiperStatus,
                    },
                    accessories: {
                        frontCrashGuard: comFrontCrashGuard,
                        loadCarrier: comLoadCarrier,
                        musicSystem: comMusicSystem,
                        tvNvideoPlayer: comTvVideo,
                        rearUnderRunProtection: comRearUnderRun,
                        sideUnderRunProtection: comSideUnderRun,
                        toolKit: comToolkit,
                    },
                    body: {
                        bodyCondition: comBodyCondition,
                    },
                },
                yourRemark: yourRemark,
                valuation: estimatedPrice,
                marketPrice: exShowRoomPrice,
                status: 4,
                statusText: 'Case Submitted',
            };
            const newcase = await dispatch(createCase(formData))
            if (newcase.payload.success) {
                alert("Case created successfully");
                dispatch(CaseActions.afterPost())
                setEmail("");
                setCnfEmail("");
                setMobile("");
                setMobileCnf("");
                setName("");
                setRole("")
                setAddress("")
                setState("")
                setCity("")
                setProductType("")
                setProductBrand("")
                setModel("")
                setVariant("")
                setLoanNo("")
                setDocVerified("")
                setRegdOwner("")
                setRegdDate("")
                setMfgdate("")
                setNumberPlateType("")
                setRegdNo("")
                setEngNo("")
                setChasisNo("")
                setBodyType("")
                setFueltype("")
                setVehicleColor("")
                setOwnerSerialNo("")
                setHypothecationBank("")
                setCubicCapacity("")
                setSeatingCapacity("")
                setVehicleWeight("")
                setSeatCover("")
                setFogLamps("")
                setTransmission("")
                setAccidental("")
                setFitnessExpiry("")
                setInsuranceExpiry("")
                setButtonLoading(false)
                navigate('/admin/submittedcases')
            }
        } else {
            setbtnPress(true);
            setButtonLoading(false)
        }
    };

    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-2 space-y-6">
                    <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
                        <div className="flex items-center justify-between py-2 px-5"
                            style={{ backgroundColor: '#4D44B5', color: '#fff', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                        >
                            <h4 className="text-lg font-medium text-default-950 capitalize" style={{ color: '#fff' }}>
                                Add Commercial Case Details
                            </h4>
                        </div>
                        <div className="p-5 border-t border-dashed border-default-200">
                            <div className="grid md:grid-cols-3 gap-4">
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Case Type *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-select-label">Case Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={caseType}
                                            label="Case Type"
                                            onChange={(e) => selectCaseChange(e)}
                                        >
                                            {selectCaseType.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fAssetType"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Asset Type *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Asset-Type">Asset Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-Asset-Type"
                                            id="demo-simple-select"
                                            value={assetType}
                                            label="Asset Type"
                                            onChange={(e) => selectAssetChange(e)}
                                        >
                                            {selectAssetType.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fValuationPurpose"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Purpose Of Valuation *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Purpose Of Valuation"
                                            variant="outlined"
                                            className={
                                                valuationPurposeError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={valuationPurpose}
                                            onChange={(e) => setValuationPurpose(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Valuation Requested By *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-requesterName" className="inputcenter">Valuation Requested By</InputLabel>
                                        <Select
                                            labelId="demo-simple-requesterName"
                                            id="demo-simple-select"
                                            value={requesterName}
                                            label="requesterName"
                                            onChange={(e) => {handleRequester(e); setRequesterName(e.target.value.name) }}
                                            style={{ height: '38px' }}
                                            inputProps={{ style: { textAlign: 'center' } }}
                                        >
                                            {clientList.map((test, index) =>
                                                <MenuItem value={test} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Requester Name *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Requester Name"
                                            variant="outlined"
                                            className={
                                                "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={requesterName}
                                            onChange={(e) => setRequesterName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Requester Branch Name *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Requester Branch Name"
                                            variant="outlined"
                                            className={
                                                requesterBranchError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={requesterBranch}
                                            onChange={(e) => setRequesterBranch(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="lName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Requester Phone *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Requester Phone"
                                            variant="outlined"
                                            className={
                                                requesterPhoneError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"

                                            value={requesterPhone}
                                            onChange={(e) => handleRequesterPhn(e)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Customer Name *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Customer Name"
                                            variant="outlined"
                                            className={
                                                nameError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="lName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Customer  Phone *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Customer Phone"
                                            variant="outlined"
                                            className={
                                                mobileError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={mobile}
                                            onChange={(e) => setNumberChange(e)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="lName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Customer Email *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Customer Email"
                                            variant="outlined"
                                            className={
                                                emailError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={email}
                                            onChange={(e) => emailChange(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="Address"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Customer Address *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Customer Address"
                                            variant="outlined"
                                            className={
                                                addressError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fState"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Select State *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-State-Type">State Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-State-Type"
                                            id="demo-simple-select"
                                            value={state}
                                            label="State"
                                            onChange={(e) => selectStateChange(e)}
                                        >
                                            {stateList.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fCity"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Select City *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-City-Type">City Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-City-Type"
                                            id="demo-simple-select"
                                            value={city}
                                            label="City"
                                            onChange={(e) => selectCityChange(e)}

                                        >
                                            {cityList.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="regdNo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        RegdNo *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Regd No"
                                            variant="outlined"
                                            className={
                                                regdNoError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={regdNo}
                                            onChange={(e) => setRegdNo(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="vehcileLocation"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle Location *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Vehicle Location"
                                            variant="outlined"
                                            className={
                                                vehicleLocationError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={vehicleLocation}
                                            onChange={(e) => setVehicleLocation(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="productType"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle brand *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Vehicle Location"
                                            variant="outlined"
                                            className={
                                                productBrandError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={productBrand}
                                            onChange={(e) => setProductBrand(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="model"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle model *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Vehicle Location"
                                            variant="outlined"
                                            className={
                                                ModelError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={model}
                                            onChange={(e) => setModel(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="variant"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle variant *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Vehicle variant"
                                            variant="outlined"
                                            className={
                                                variantError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={variant}
                                            onChange={(e) => setVariant(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="loanNo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Loan Agreement No *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Loan Agreement No"
                                            variant="outlined"
                                            className={
                                                loanNoError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={loanNo}
                                            onChange={(e) => setLoanNo(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fDocVerified"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Document Verified *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Document-Verified">Doc Verified</InputLabel>
                                        <Select
                                            labelId="demo-simple-Document-Verified"
                                            id="demo-simple-select"
                                            value={docVerified}
                                            label="Doc Verified"
                                            onChange={(e) => selectDocuementVerifiedTypeChange(e)}

                                        >
                                            {documents.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="regdOwner"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Regd Owner *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Regd Owner"
                                            variant="outlined"
                                            className={
                                                regdOwnerError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={regdOwner}
                                            onChange={(e) => setRegdOwner(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="regdOwner"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Regd Date *
                                    </label>
                                    <input
                                        type="date"
                                        className={
                                            regdDateError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="regdDate"
                                        placeholder="Enter product regd date"
                                        value={regdDate}
                                        onChange={(e) => setRegdDate(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fManufacturingDate"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Manufacturing Date <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <input
                                        type="date"
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fManufacturingDate"
                                        placeholder="Enter Manufacturing Date"
                                        value={mfgDate}
                                        onChange={(e) => setMfgdate(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fNumberPlate"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Number Plate Type *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Number-Plate-Type">Number Plate Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-Number-Plate-Type"
                                            id="demo-simple-select"
                                            value={numberPlateType}
                                            label="Number Plate Type"
                                            onChange={(e) => selectNumberPlateTypeChange(e)}

                                        >
                                            {number.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="engNo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Engine No *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Engine No"
                                            variant="outlined"
                                            className={
                                                engNoError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={engNo}
                                            onChange={(e) => setEngNo(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="chasisNo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Chasis No *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Chasis No"
                                            variant="outlined"
                                            className={
                                                chasisNoError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={chasisNo}
                                            onChange={(e) => setChasisNo(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="bodyType"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Body Type *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Body Type"
                                            variant="outlined"
                                            className={
                                                bodyTypeError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={bodyType}
                                            onChange={(e) => setBodyType(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fMeterReading"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Meter Reading *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Meter Reading"
                                            variant="outlined"
                                            className="textfield"
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={meterReading}
                                            onChange={(e) => setMeterReading(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fueltype"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Fuel Type*
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Fuel Type"
                                            variant="outlined"
                                            className={
                                                fueltypeError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={fueltype}
                                            onChange={(e) => setFueltype(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fueltype"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle Color *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Vehicle Color"
                                            variant="outlined"
                                            className={
                                                vehicleColorError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={vehicleColor}
                                            onChange={(e) => setVehicleColor(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="vehicleWeight"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Gross Weight Of Vehicle *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Gross Weight Of Vehicle"
                                            variant="outlined"
                                            className={
                                                vehicleWeightError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={vehicleWeight}
                                            onChange={(e) => setVehicleWeight(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="ownerSerialNo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle Owner Serial No *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Vehicle Owner Serial No"
                                            variant="outlined"
                                            className={
                                                ownerSerialNoError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={ownerSerialNo}
                                            onChange={(e) => setOwnerSerialNo(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="ownerSerialNo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Hypothecation Bank Name *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Hypothecation Bank"
                                            variant="outlined"
                                            className={
                                                hypothecationBankError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={hypothecationBank}
                                            onChange={(e) => setHypothecationBank(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fInsuranceCompany"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Insurance Company Name *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Hypothecation Bank"
                                            variant="outlined"
                                            className="textfield"
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={insuranceCompany}
                                            onChange={(e) => setInsuranceCompany(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fInsuranceStatus"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Insurance Expired *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Insurance-Expired">Insurance Expired</InputLabel>
                                        <Select
                                            labelId="demo-simple-Insurance-Expired"
                                            id="demo-simple-select"
                                            value={insuranceStatus}
                                            label="Insurance Status"
                                            onChange={(e) => selectInsuranceStatusChange(e)}

                                        >
                                            {options.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="cubicCapacity"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Cubic  Capacity *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Cubic Capacity"
                                            variant="outlined"
                                            className={
                                                cubicCapacityError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={cubicCapacity}
                                            onChange={(e) => setCubicCapacity(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="seatingCapacity"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Seating Capacity *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Seating Capacity"
                                            variant="outlined"
                                            className={
                                                eatingCapacityError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={seatingCapacity}
                                            onChange={(e) => setSeatingCapacity(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-3 space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle Video *
                                    </label>
                                    {
                                        showLoader === true ? (<>
                                            <div style={{ height: '250px', width: '50%', backgroundColor: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            </div>
                                        </>) : (<>
                                            <iframe
                                                title="Video Player"
                                                width="50%" // Set width to 100% to fill the container
                                                height="250px" // Set height to auto to maintain aspect ratio
                                                src={videoRecord}
                                                frameBorder="0"
                                                allowFullScreen
                                            ></iframe>
                                        </>)
                                    }
                                    <input type="file" id='videouploading' onChange={handleFileChange} accept="video/*" />
                                    {
                                        selectedVideo ? (
                                            <div>
                                                <button onClick={handleUpload} style={{ backgroundColor: '#ff6600', color: '#fff', padding: '5px 8px', borderRadius: '4px' }}>Upload Video</button>
                                            </div>) : (
                                            <div>
                                                <button style={{ backgroundColor: '#ff6600', opacity: '.5', color: '#fff', padding: '5px 8px', borderRadius: '4px' }}>Upload Video</button>
                                            </div>)
                                    }
                                </div>
                                <div className="col-span-3 space-y-1">
                                    <ImageUpload />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="vehicleWeight"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Selfie With Vehicle *
                                    </label>
                                    <input type="file" onChange={handleSelfieWithVehicleChange}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isSelfieWithAgentLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={selfieWithVehicleImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>

                                <div className="space-y-1">
                                    <label
                                        htmlFor="Chasis Image"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Chasis Image *
                                    </label>
                                    <input type="file" onChange={handleChasisChange}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isChasisImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={chasisImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>

                                <div className="space-y-1">
                                    <label
                                        htmlFor="formatfile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Product Format Image *
                                    </label>
                                    <input type="file" onChange={handleFormatChange}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {IsformatImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={formatImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="openBonnetfile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Open Bonnet Image *
                                    </label>
                                    <input type="file" onChange={handleOpenBonnetChange}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isOpenBonnetLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={openBonnet} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="openBonnetfile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Right 45* Image *
                                    </label>
                                    <input type="file" onChange={handleright45Change}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isRight45ImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={right45image} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="frontImage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front Image *
                                    </label>
                                    <input type="file" onChange={frontImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isFrontImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={frontimage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="frontUnderCar"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front Under Car Image *
                                    </label>
                                    <input type="file" onChange={frontUnderCarImg}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isFrontUnderCarImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={frontUnderCarImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="left45file"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Left 45* Image *
                                    </label>
                                    <input type="file" onChange={handlesetLeft45Image}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isLeft45Loading ? (
                                            <div></div>
                                        ) : (
                                            <img src={left45image} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="leftfile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Car Left Image *
                                    </label>
                                    <input type="file" onChange={handlesetLeftImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isLeftImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={leftImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="rearfile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Car Rear Image *
                                    </label>
                                    <input type="file" onChange={handlesetRearImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isRearImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={rearImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="rearunderpartfile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Car Rear Under Part Image *
                                    </label>
                                    <input type="file" onChange={handlerearUnderPartImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isRearUnderPartLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={rearUnderPartImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="rearunderpartfile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Open Dickey Image *
                                    </label>
                                    <input type="file" onChange={handlerearOpenDickeyImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isOpenDickeyImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={openDickeyImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="handlerightImage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Open Handle Right Image *
                                    </label>
                                    <input type="file" onChange={HandlerightImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isRightImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={rightImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="tyreImage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Tyre Image *
                                    </label>
                                    <input type="file" onChange={handlesetTyreImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isTyreImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={tyreImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="glassOutside"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Glass Outside Image*
                                    </label>
                                    <input type="file" onChange={handleGlassOutsideImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isGlassOutsideImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={glassOutsideImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="glassOutside"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Glass Inside Image*
                                    </label>
                                    <input type="file" onChange={handleGlassInsideImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isGlassInsideImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={glassInsideImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="odometer"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Odometer Image*
                                    </label>
                                    <input type="file" onChange={handleOdometerImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isOdometerImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={odometerImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="rpmFile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        RPM Image*
                                    </label>
                                    <input type="file" onChange={handleRpmImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isRpmImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={rpmImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="anyDamage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Any Damage *
                                    </label>
                                    <input type="file" onChange={handleAnyDamage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isAnyDamageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={anyDamage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="anyDamage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Extra Damage *
                                    </label>
                                    <input type="file" onChange={handleExtraImage}
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isExtraImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={extraImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="anyDamage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Extra Image 1 *
                                    </label>
                                    <input type="file" onChange={handleExtraImage1}
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isExtraImage1Loading ? (
                                            <div></div>
                                        ) : (
                                            <img src={extraImage1} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="anyDamage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Extra Image 2 *
                                    </label>
                                    <input type="file" onChange={handleExtraImage2}
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isExtraImage2Loading ? (
                                            <div></div>
                                        ) : (
                                            <img src={extraImage2} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="anyDamage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Extra Image 3 *
                                    </label>
                                    <input type="file" onChange={handleExtraImage3}
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isExtraImage3Loading ? (
                                            <div></div>
                                        ) : (
                                            <img src={extraImage3} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="signature"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Upload Your Signature*
                                    </label>

                                    <input type="file" onChange={handleSignature}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isSignatureImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={signatureImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="frontRc"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Upload Frontside of your RC*
                                    </label>
                                    <input type="file" onChange={handleFrontRc}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isRcFrontImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={rcFrontImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="backRc"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Upload Frontside of your RC*
                                    </label>
                                    <input type="file" onChange={handleBackRc}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isRcBackImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={rcBackImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="airConditioner"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Air Conditioner *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Air-Conditioner">Air Conditioner</InputLabel>
                                        <Select
                                            labelId="demo-simple-Air-Conditioner"
                                            id="demo-simple-select"
                                            value={airConditioner}
                                            label="Air Conditioner"
                                            onChange={(e) => selectAirConditionerChange(e)}

                                        >
                                            {options.map((test, index) =>
                                                <MenuItem selected={test.name === airConditioner} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="cdCharge"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Cd Charge *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Cd-Charge">Cd Charge</InputLabel>
                                        <Select
                                            labelId="demo-simple-Cd-Charge"
                                            id="demo-simple-select"
                                            value={cdCharge}
                                            label="Cd Charge"
                                            onChange={(e) => selectCdChargeChange(e)}

                                        >
                                            {options.map((test, index) =>
                                                <MenuItem selected={test.name === cdCharge} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="seatCover"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Seat Cover *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Seat-Cover">Seat Cover</InputLabel>
                                        <Select
                                            labelId="demo-simple-Seat-Cover"
                                            id="demo-simple-select"
                                            value={seatCover}
                                            label="Seat Cover"
                                            onChange={(e) => selectSeatCoverChange(e)}

                                        >
                                            {options.map((test, index) =>
                                                <MenuItem selected={test.name === seatCover} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fogLamps"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Fog Lamps *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Fog-Lamps">Fog Lamps</InputLabel>
                                        <Select
                                            labelId="demo-simple-Fog-Lamps"
                                            id="demo-simple-select"
                                            value={fogLamps}
                                            label="Fog Lamps"
                                            onChange={(e) => selectFogLampsChange(e)}

                                        >
                                            {options.map((test, index) =>
                                                <MenuItem selected={test.name === fogLamps} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="transmission"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Transmission *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Transmission">Transmission</InputLabel>
                                        <Select
                                            labelId="demo-simple-Transmission"
                                            id="demo-simple-select"
                                            value={transmission}
                                            label="Transmission"
                                            onChange={(e) => selectTransmissionChange(e)}

                                        >
                                            {Features.map((test, index) =>
                                                <MenuItem selected={test.name === transmission} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="odometer"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Odometer *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Odometer">Odometer</InputLabel>
                                        <Select
                                            labelId="demo-simple-Odometer"
                                            id="demo-simple-select"
                                            value={odometer}
                                            label="Odometer"
                                            onChange={(e) => selectOdometerChange(e)}

                                        >
                                            {Odometer.map((test, index) =>
                                                <MenuItem selected={test.name === odometer} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="accidental"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Accidental *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Accidental">Accidental</InputLabel>
                                        <Select
                                            labelId="demo-simple-Accidental"
                                            id="demo-simple-select"
                                            value={accidental}
                                            label="Accidental"
                                            onChange={(e) => selectAccidentalChange(e)}

                                        >
                                            {options.map((test, index) =>
                                                <MenuItem selected={test.name === accidental} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="otherRepair"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Other Repair *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Other-Repair">Other Repair</InputLabel>
                                        <Select
                                            labelId="demo-simple-Other-Repair"
                                            id="demo-simple-select"
                                            value={otherRepair}
                                            label="Other Repair"
                                            onChange={(e) => selectotherRepairlChange(e)}

                                        >
                                            {options.map((test, index) =>
                                                <MenuItem selected={test.name === otherRepair} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fitnessExpiry"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Fitness Expiry *
                                    </label>
                                    <input
                                        type="Date"
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fitnessExpiry"
                                        placeholder="Enter FitnessExpiry"
                                        value={fitnessExpiry}
                                        onChange={(e) => setFitnessExpiry(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="roadtaxValidity"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Roadtax Validity *
                                    </label>
                                    <input
                                        type="Date"
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="roadtaxValidity"
                                        placeholder="Enter RoadtaxValidity"
                                        value={roadtaxValidity}
                                        onChange={(e) => setroadtaxValidity(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fIdvValue"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Idv Value *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Idv Value"
                                            variant="outlined"
                                            className="textfield"
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={idvValue}
                                            onChange={(e) => setIdvValue(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fInsuranceExpiry"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Insurance Expiry *
                                    </label>
                                    <input
                                        type="date"
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fInsuranceExpiry"
                                        placeholder="Enter Insurance Expiry"
                                        value={insuranceExpiry}
                                        onChange={(e) => setInsuranceExpiry(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="engineCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Engine Condition <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Engine-Condition">Engine Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-Engine-Condition"
                                            id="demo-simple-select"
                                            value={engineCondition}
                                            label="Engine Condition"
                                            onChange={(e) => selectEngineConditionChange(e)}

                                        >
                                            {reqCondition.map((test, index) =>
                                                <MenuItem
                                                    selected={test.name === engineCondition}
                                                    value={test.name} key={index}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="wheelType"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Wheel Type *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Wheel-Type">Wheel Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-Wheel-Type"
                                            id="demo-simple-select"
                                            value={wheelType}
                                            label="Wheel Type"
                                            onChange={(e) => selectWheelTypeChange(e)}
                                        >
                                            {reqCondition.map((test, index) =>
                                                <MenuItem
                                                    selected={test.name === wheelType}
                                                    value={test.name} key={index}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fTotalTyre"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Total Tyre * (Type In Numbers)
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Total Tyre"
                                            variant="outlined"
                                            className={"textfield"}
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={totalTyre}
                                            onChange={(e) => setTotalTyre(e.target.value)}
                                        // className={classes.textfield}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fTotalTyre"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Available Tyre * (Type In Numbers)
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Available Tyre"
                                            variant="outlined"
                                            className={"textfield"}
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={availableTyre}
                                            onChange={(e) => setAvailableTyre(e.target.value)}
                                        // className={classes.textfield}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fMissingTyre"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Missing Tyre * (Type In Numbers)
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Missing Tyre"
                                            variant="outlined"
                                            className={"textfield"}
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={missingTyre}
                                            onChange={(e) => setMissingTyre(e.target.value)}
                                        // className={classes.textfield}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fSpareTyre"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Spare Tyre * (Type In Numbers)
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Spare Tyre"
                                            variant="outlined"
                                            className={"textfield"}
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={spareTyre}
                                            onChange={(e) => setSpareTyre(e.target.value)}
                                        // className={classes.textfield}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="interiorCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Interior Condition <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Interior-Condition">Interior Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-Interior-Condition"
                                            id="demo-simple-select"
                                            value={interiorCondition}
                                            label="Interior Condition"
                                            onChange={(e) => selectInteriorConditionChange(e)}

                                        >
                                            {reqCondition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === interiorCondition}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="exteriorCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Exterior Condition <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Exterior-Condition">Exterior Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-Exterior-Condition"
                                            id="demo-simple-select"
                                            value={exteriorCondition}
                                            label="Exterior Condition"
                                            onChange={(e) => selectExteriorConditionChange(e)}

                                        >
                                            {reqCondition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === exteriorCondition}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="headLamp"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Head Lamp *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Head-Lamp">Head Lamp</InputLabel>
                                        <Select
                                            labelId="demo-simple-Head-Lamp"
                                            id="demo-simple-select"
                                            value={headLamp}
                                            label="Head Lamp"
                                            onChange={(e) => selectHeadLampChange(e)}

                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === headLamp}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="tailLamp"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Tail Lamp *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Tail-Lamp">Tail Lamp</InputLabel>
                                        <Select
                                            labelId="demo-simple-Tail-Lamp"
                                            id="demo-simple-select"
                                            value={tailLamp}
                                            label="Tail Lamp"
                                            onChange={(e) => selectTailLampChange(e)}

                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === tailLamp}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="frontIndicators"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front Indicators *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Front-Indicators">Front Indicators</InputLabel>
                                        <Select
                                            labelId="demo-simple-Front-Indicators"
                                            id="demo-simple-select"
                                            value={frontIndicators}
                                            label="Front Indicators"
                                            onChange={(e) => selectFrontIndicatorsChange(e)}

                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === frontIndicators}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="rearIndicators"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Rear Indicators *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Rear-Indicators">Rear Indicators</InputLabel>
                                        <Select
                                            labelId="demo-simple-Rear-Indicators"
                                            id="demo-simple-select"
                                            value={rearIndicators}
                                            label="Rear Indicators"
                                            onChange={(e) => selectRearIndicatorsChange(e)}

                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === rearIndicators}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="maintenanceLevel"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Maintenance Level *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Maintenance Level">Maintenance Level</InputLabel>
                                        <Select
                                            labelId="demo-simple-Maintenance Level"
                                            id="demo-simple-select"
                                            value={maintenanceLevel}
                                            label="Maintenance Level"
                                            onChange={(e) => selectMaintenanceLevelChange(e)}

                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === maintenanceLevel}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="frontBrakeCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        FrontBreak Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-frontBrakeCondition">FrontBrake Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-frontBrakeCondition"
                                            id="demo-simple-select"
                                            value={frontBrakeCondition}
                                            label="frontBrakeCondition"
                                            onChange={(e) => selectFrontBreakConditionChange(e)}

                                        >
                                            {reqCondition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === frontBrakeCondition}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="rearBrakeCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Rear Break Condition <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-RearBrakeCondition">RearBrake Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-RearBrakeCondition"
                                            id="demo-simple-select"
                                            value={rearBrakeCondition}
                                            label="rearBrakeCondition"
                                            onChange={(e) => selectRearBreakConditionChange(e)}

                                        >
                                            {reqCondition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === rearBrakeCondition}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comFrontCrashGuard"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front CrashGuard *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comFrontCrashGuard">Front CrashGuard</InputLabel>
                                        <Select
                                            labelId="demo-simple-comFrontCrashGuard"
                                            id="demo-simple-select"
                                            value={comFrontCrashGuard}
                                            label="comFrontCrashGuard"
                                            onChange={(e) => selectsetFrontCrashGuardChange(e)}

                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comFrontCrashGuard}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comLoadCarrier"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Load Carrier *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comLoadCarrier">Load Carrier</InputLabel>
                                        <Select
                                            labelId="demo-simple-comLoadCarrier"
                                            id="demo-simple-select"
                                            value={comLoadCarrier}
                                            label="comLoadCarrier"
                                            onChange={(e) => selectComLoadCarrierChange(e)}

                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comLoadCarrier}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comMusicSystem"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Music System *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comMusicSystem">Music System</InputLabel>
                                        <Select
                                            labelId="demo-simple-comMusicSystem"
                                            id="demo-simple-select"
                                            value={comMusicSystem}
                                            label="comMusicSystem"
                                            onChange={(e) => selectComMusicSystemChange(e)}

                                        >
                                            {options.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comMusicSystem}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comRearUnderRun"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Rear UnderRun *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comRearUnderRun">Rear UnderRun</InputLabel>
                                        <Select
                                            labelId="demo-simple-comRearUnderRun"
                                            id="demo-simple-select"
                                            value={comRearUnderRun}
                                            label="comRearUnderRun"
                                            onChange={(e) => selectComMusicSystemChange(e)}

                                        >
                                            {options.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comRearUnderRun}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comSideUnderRun"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Side UnderRun *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comSideUnderRun">Side UnderRun</InputLabel>
                                        <Select
                                            labelId="demo-simple-comSideUnderRun"
                                            id="demo-simple-select"
                                            value={comSideUnderRun}
                                            label="comSideUnderRun"
                                            onChange={(e) => selectComSideUnderRunChange(e)}

                                        >
                                            {options.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comSideUnderRun}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comToolkit"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Toolkit *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comToolkit">Toolkit</InputLabel>
                                        <Select
                                            labelId="demo-simple-comToolkit"
                                            id="demo-simple-select"
                                            value={comToolkit}
                                            label="comToolkit"
                                            onChange={(e) => selectComToolkitChange(e)}

                                        >
                                            {options.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comToolkit}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comTvVideo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Tv & Video  *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comTvVideo">Tv-Video</InputLabel>
                                        <Select
                                            labelId="demo-simple-comTvVideo"
                                            id="demo-simple-select"
                                            value={comTvVideo}
                                            label="comTvVideo"
                                            onChange={(e) => selectComTvVideoChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comTvVideo}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comBodyCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Body Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comBodyCondition">Body Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-comBodyCondition"
                                            id="demo-simple-select"
                                            value={comBodyCondition}
                                            label="comBodyCondition"
                                            onChange={(e) => selectComBodyConditionChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comBodyCondition}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comHandBrake"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Hand Brake *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comHandBrake">Hand Brake</InputLabel>
                                        <Select
                                            labelId="demo-simple-comHandBrake"
                                            id="demo-simple-select"
                                            value={comHandBrake}
                                            label="comHandBrake"
                                            onChange={(e) => selectComHandBrakeChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comHandBrake}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comDoorCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Door Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comDoorCondition">Door Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-comDoorCondition"
                                            id="demo-simple-select"
                                            value={comDoorCondition}
                                            label="comDoorCondition"
                                            onChange={(e) => selectComDoorConditionChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comDoorCondition}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comSideGlassesCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Side Glasses Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comSideGlassesCondition">SideGlasses Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-comSideGlassesCondition"
                                            id="demo-simple-select"
                                            value={comSideGlassesCondition}
                                            label="comSideGlassesCondition"
                                            onChange={(e) => selectComSideGlassesConditionChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comSideGlassesCondition}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comWindscreenCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Wind Screen Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comWindscreenCondition">Windscreen Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-comWindscreenCondition"
                                            id="demo-simple-select"
                                            value={comWindscreenCondition}
                                            label="comWindscreenCondition"
                                            onChange={(e) => selectComWindscreenConditionChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comWindscreenCondition}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comBatteryCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Battery Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comBatteryCondition">Battery Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-comBatteryCondition"
                                            id="demo-simple-select"
                                            value={comBatteryCondition}
                                            label="comBatteryCondition"
                                            onChange={(e) => selectComBatteryConditionChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comBatteryCondition}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comClusterUnitCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Cluster Unit Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-UnitCondition">Unit Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-UnitCondition"
                                            id="demo-simple-select"
                                            value={comClusterUnitCondition}
                                            label="comClusterUnitCondition"
                                            onChange={(e) => selectComClusterUnitConditionChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comClusterUnitCondition}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comFogLampStatus"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        FogLamp Status *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comFogLampStatus">FogLamp Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-comFogLampStatus"
                                            id="demo-simple-select"
                                            value={comFogLampStatus}
                                            label="comFogLampStatus"
                                            onChange={(e) => selectComFogLampStatusChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comFogLampStatus}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="space-y-1">
                                    <label
                                        htmlFor="fBatteryNo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        No Of Batteries  *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Battery No"
                                            variant="outlined"
                                            className={`textfield`}
                                            style={{ marginTop: '3px' }}
                                            type="text"
                                            id="text"
                                            value={comBatteryNo}
                                            onChange={(e) => setComBatteryNo(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comWiperStatus"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Wiper Status *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comWiperStatus">Wiper Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-comWiperStatus"
                                            id="demo-simple-select"
                                            value={comWiperStatus}
                                            label="comWiperStatus"
                                            onChange={(e) => selectComWiperStatusChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comWiperStatus}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comEngineStatus"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Engine Status *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comEngineStatus">Engine Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-comEngineStatus"
                                            id="demo-simple-select"
                                            value={comEngineStatus}
                                            label="comEngineStatus"
                                            onChange={(e) => selectComEngineStatusChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comEngineStatus}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comEngineShieldCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Engine Shield Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comEngineShieldCondition">Engine Shield Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-comEngineShieldCondition"
                                            id="demo-simple-select"
                                            value={comEngineShieldCondition}
                                            label="comEngineShieldCondition"
                                            onChange={(e) => selectComEngineShieldConditionChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comEngineStatus}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comFluidLeakStatus"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Fluid Leak Status *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comFluidLeakStatus">Fluid Leak Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-comFluidLeakStatus"
                                            id="demo-simple-select"
                                            value={comFluidLeakStatus}
                                            label="comFluidLeakStatus"
                                            onChange={(e) => selectComFluidLeakStatusChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comFluidLeakStatus}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comSuspensionCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Suspension Condition <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comSuspensionCondition">Suspension Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-comSuspensionCondition"
                                            id="demo-simple-select"
                                            value={comSuspensionCondition}
                                            label="comSuspensionCondition"
                                            onChange={(e) => selectComSuspensionConditionChange(e)}
                                        >
                                            {reqCondition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comSuspensionCondition}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comTransmissionCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Transmission Condition <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comTransmissionCondition">Transmission Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-comTransmissionCondition"
                                            id="demo-simple-select"
                                            value={comTransmissionCondition}
                                            label="comTransmissionCondition"
                                            onChange={(e) => selectComTransmissionConditionChange(e)}
                                        >
                                            {reqCondition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comTransmissionCondition}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="space-y-1">
                                    <label
                                        htmlFor="comAxleOneLeftIn"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Axle OneLeft In  *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comAxleOneLeftIn">Axle OneLeft In</InputLabel>
                                        <Select
                                            labelId="demo-simple-comAxleOneLeftIn"
                                            id="demo-simple-select"
                                            value={comAxleOneLeftIn}
                                            label="comAxleOneLeftIn"
                                            onChange={(e) => selectComAxleOneLeftInChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comAxleOneLeftIn}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comAxleOneLeftOut"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Axle OneLeft Out  *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comAxleOneLeftOut">Axle OneLeft Out</InputLabel>
                                        <Select
                                            labelId="demo-simple-comAxleOneLeftOut"
                                            id="demo-simple-select"
                                            value={comAxleOneLeftOut}
                                            label="comAxleOneLeftOut"
                                            onChange={(e) => selectComAxleOneLeftOutChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comAxleOneLeftOut}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comAxleOneRightIn"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Axle OneRight In  *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comAxleOneRightIn">Axle OneRight In</InputLabel>
                                        <Select
                                            labelId="demo-simple-comAxleOneRightIn"
                                            id="demo-simple-select"
                                            value={comAxleOneRightIn}
                                            label="comAxleOneRightIn"
                                            onChange={(e) => selectComAxleOneRightInChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comAxleOneRightIn}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comAxleOneRightOut"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Axle OneRight Out  *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comAxleOneRightOut">Axle OneRight Out</InputLabel>
                                        <Select
                                            labelId="demo-simple-comAxleOneRightOut"
                                            id="demo-simple-select"
                                            value={comAxleOneRightOut}
                                            label="comAxleOneRightOut"
                                            onChange={(e) => selectComAxleOneRightOutChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comAxleOneRightOut}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comAxleThreeLeftIn"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Axle ThreeLeft In  *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comAxleThreeLeftIn">Axle ThreeLeft In</InputLabel>
                                        <Select
                                            labelId="demo-simple-comAxleThreeLeftIn"
                                            id="demo-simple-select"
                                            value={comAxleThreeLeftIn}
                                            label="comAxleThreeLeftIn"
                                            onChange={(e) => selectComAxleThreeLeftInChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comAxleThreeLeftIn}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comAxleThreeLeftOut"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Axle ThreeLeft Out  *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comAxleThreeLeftOut">Axle ThreeLeft Out</InputLabel>
                                        <Select
                                            labelId="demo-simple-comAxleThreeLeftOut"
                                            id="demo-simple-select"
                                            value={comAxleThreeLeftOut}
                                            label="comAxleThreeLeftOut"
                                            onChange={(e) => selectComAxleThreeLeftOutChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comAxleThreeLeftOut}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comAxleThreeRightIn"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Axle ThreeRight In  *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comAxleThreeRightIn">Axle ThreeRight In</InputLabel>
                                        <Select
                                            labelId="demo-simple-comAxleThreeRightIn"
                                            id="demo-simple-select"
                                            value={comAxleThreeRightIn}
                                            label="comAxleThreeRightIn"
                                            onChange={(e) => selectComAxleThreeRightInChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comAxleThreeRightIn}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comAxleThreeRightOut"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Axle ThreeRight Out  *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comAxleThreeRightOut">Axle ThreeRight Out</InputLabel>
                                        <Select
                                            labelId="demo-simple-comAxleThreeRightOut"
                                            id="demo-simple-select"
                                            value={comAxleThreeRightOut}
                                            label="comAxleThreeRightOut"
                                            onChange={(e) => selectComAxleThreeRightOutChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comAxleThreeRightOut}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comAxleTwoLeftIn"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Axle TwoLeft In  *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comAxleTwoLeftIn">Axle TwoLeft In</InputLabel>
                                        <Select
                                            labelId="demo-simple-comAxleTwoLeftIn"
                                            id="demo-simple-select"
                                            value={comAxleTwoLeftIn}
                                            label="comAxleTwoLeftIn"
                                            onChange={(e) => selectComAxleTwoLeftInChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comAxleTwoLeftIn}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comAxleTwoLeftOut"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Axle TwoLeft Out  *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comAxleTwoLeftOut">Axle TwoLeft Out</InputLabel>
                                        <Select
                                            labelId="demo-simple-comAxleTwoLeftOut"
                                            id="demo-simple-select"
                                            value={comAxleTwoLeftOut}
                                            label="comAxleTwoLeftOut"
                                            onChange={(e) => selectComAxleTwoLeftOutChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comAxleTwoLeftOut}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comAxleTwoRightIn"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Axle TwoRight In  *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comAxleTwoRightIn">Axle TwoRight In</InputLabel>
                                        <Select
                                            labelId="demo-simple-comAxleTwoRightIn"
                                            id="demo-simple-select"
                                            value={comAxleTwoRightIn}
                                            label="comAxleTwoRightIn"
                                            onChange={(e) => selectComAxleTwoRightInChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comAxleTwoRightIn}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comAxleTwoRightOut"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Axle TwoRight Out  *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comAxleTwoRightOut">Axle TwoRight Out</InputLabel>
                                        <Select
                                            labelId="demo-simple-comAxleTwoRightOut"
                                            id="demo-simple-select"
                                            value={comAxleTwoRightOut}
                                            label="comAxleTwoRightOut"
                                            onChange={(e) => selectComAxleTwoRightOutChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comAxleTwoRightOut}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comFrontOneLeft"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front OneLeft *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comFrontOneLeft">Front OneLeft</InputLabel>
                                        <Select
                                            labelId="demo-simple-comFrontOneLeft"
                                            id="demo-simple-select"
                                            value={comFrontOneLeft}
                                            label="comFrontOneLeft"
                                            onChange={(e) => selectComFrontOneLeftChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comFrontOneLeft}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comFrontOneRight"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front OneRight  *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comFrontOneRight">Front OneRight</InputLabel>
                                        <Select
                                            labelId="demo-simple-comFrontOneRight"
                                            id="demo-simple-select"
                                            value={comFrontOneRight}
                                            label="comFrontOneRight"
                                            onChange={(e) => selectComFrontOneRightChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comFrontOneRight}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comFrontTwoLeft"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front TwoLeft  *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comFrontTwoLeft">Front TwoLeft</InputLabel>
                                        <Select
                                            labelId="demo-simple-comFrontTwoLeft"
                                            id="demo-simple-select"
                                            value={comFrontTwoLeft}
                                            label="comFrontTwoLeft"
                                            onChange={(e) => selectComFrontTwoLeftChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comFrontTwoLeft}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comFrontTwoRight"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front TwoRight  *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comFrontTwoRight">Front TwoRight</InputLabel>
                                        <Select
                                            labelId="demo-simple-comFrontTwoRight"
                                            id="demo-simple-select"
                                            value={comFrontTwoRight}
                                            label="comFrontTwoRight"
                                            onChange={(e) => selectComFrontTwoRightChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comFrontTwoRight}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comFrontTruckTyreCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front TruckTyre Condition  <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comFrontTruckTyreCondition">Front TruckTyre Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-comFrontTruckTyreCondition"
                                            id="demo-simple-select"
                                            value={comFrontTruckTyreCondition}
                                            label="comFrontTruckTyreCondition"
                                            onChange={(e) => selectComFrontTruckTyreConditionChange(e)}
                                        >
                                            {reqCondition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comFrontTruckTyreCondition}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comFrontTruckTyreType"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front TruckTyre Type  *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Tyre Type"
                                            variant="outlined"
                                            className={
                                                `textfield`
                                            }
                                            style={{ marginTop: '3px' }}
                                            type="text"
                                            id="text"
                                            value={comFrontTruckTyreType}
                                            onChange={(e) => setComFrontTruckTyreType(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="comAxleCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Axle Condition  *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-comAxleCondition">Axle Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-comAxleCondition"
                                            id="demo-simple-select"
                                            value={comAxleCondition}
                                            label="comAxleCondition"
                                            onChange={(e) => selectComAxleConditionChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === comAxleCondition}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="space-y-1">
                                    <label
                                        htmlFor="yourRemark"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Your Remark On Vehicle *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-yourRemark">Your Remark</InputLabel>
                                        <Select
                                            labelId="demo-simple-yourRemark"
                                            id="demo-simple-select"
                                            value={yourRemark}
                                            label="yourRemark"
                                            onChange={(e) => selectYourRemarkChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem
                                                    value={test.name}
                                                    key={index}
                                                    selected={test.name === yourRemark}
                                                >
                                                    {test.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fExShowRoomPrice"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Ex-ShowRoom Price <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Ex-ShowRoom Price"
                                            variant="outlined"
                                            className={
                                                exShowRoomPriceError === ""
                                                    ? `textfield`
                                                    : `textfield error_class`
                                            }
                                            style={{ marginTop: '3px' }}
                                            type="text"
                                            id="text"
                                            value={exShowRoomPrice}
                                            onChange={(e) => setExShowRoomPrice(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fEstimatedPrice"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Estimated Price <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Estimated Price"
                                            variant="outlined"
                                            className={
                                                estimatedPriceError === ""
                                                    ? `textfield`
                                                    : `textfield error_class`
                                            }
                                            style={{ marginTop: '3px' }}
                                            type="text"
                                            id="text"
                                            value={estimatedPrice}
                                            onChange={(e) => setEstimatedPrice(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center gap-2 justify-end" style={{ marginBottom: '80px' }}>
                        <button
                            onClick={(e) => cancelClick(e)}
                            className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border border-transparent align-middle duration-500 text-sm text-center text-red-600 hover:border-red-500/20 hover:bg-red-500/20 rounded transition-all"
                            style={{ outline: 'none', border: 'none', color: '#fff', backgroundColor: 'red' }}
                        >
                            <i className="ph-bold ph-x text-lg" /> cancel
                        </button>
                        {
                            buttonLoading ?
                                <button
                                    className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                                    style={{ background: "grey", outline: 'none', border: 'none' }}
                                >
                                    <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                                    Submitting
                                </button>
                                :
                                <button
                                    className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                                    onClick={(e) => handlesubmit(e)}
                                    style={{ background: "#4D44B5", outline: 'none', border: 'none' }}
                                >
                                    <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                                    Submit
                                </button>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddCommercialCase;
