import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createEmployee, updateEmployee } from "../../redux/Employee/employeeSlice";
import { FileUploader } from "react-drag-drop-files";
import { CaseActions, UploadChasisImage, UploadFrontImage, UploadOpenBonnet, UploadRearUnderPart, UploadRight45Image, createCase, selfieWithVehicle, sendVehicleNumber, uploadAnyDamage, uploadFrontUnderCar, uploadGlassInsideImage, uploadGlassOutside, uploadLeft45, uploadLeftImage, uploadOdometerImage, uploadOpenDickey, uploadRcBackImage, uploadRcFront, uploadRearImage, uploadRightImage, uploadRpmImage, uploadSignature, uploadTyreImage, uploadformatImage, validateEmail, validateMobile, validateNumber } from "../../redux/Cases/CaseSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { InputAdornment, TextField } from "@mui/material";

const CreateCase = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { totalCars, loginData } = useSelector((store) => store.auth);
  const { employeeList } = useSelector((store) => store.employee);
  const { totalClients } = useSelector((store) => store.client);
  const [caseType, setCaseType] = useState('');
  const [caseTypeError, setCaseTypeError] = useState('');
  const [assetType, setAssetType] = useState('');
  const [assetTypeError, setAssetTypeError] = useState('');
  const [valuationPurpose, setValuationPurpose] = useState('Assesment of fair market value');
  const [valuationPurposeError, setValuationPurposeError] = useState('');
  const [email, setEmail] = useState("");
  const [Cnfemail, setCnfEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailExistError, setEmailExistError] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileCnf, setMobileCnf] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [mobileExistError, setMobileExistError] = useState("");
  const [requesterID, setRequesterID] = useState("");
  const [requesterName, setRequesterName] = useState("");
  const [requesterNameError, setRequesterNameError] = useState("");
  const [requesterBranch, setRequesterBranch] = useState("");
  const [requesterBranchError, setRequesterBranchError] = useState("");
  const [requesterPhone, setRequesterPhone] = useState("");
  const [requesterPhoneError, setRequesterPhoneError] = useState("");
  const [requesterEmail, setRequesterEmail] = useState("");
  const [requesterEmailError, setRequesterEmailError] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [address, setAddress] = useState("")
  const [addressError, setAddressError] = useState("")
  const [filterState, setFilterState] = useState([])
  const [state, setState] = useState("")
  const [stateError, setStateError] = useState("")
  const [cityList, setCityList] = useState([]);
  const [city, setCity] = useState("")
  const [cityError, setCityError] = useState("")
  const [vehicleLocation, setVehicleLocation] = useState('')
  const [vehicleLocationError, setVehicleLocationError] = useState('')
  const [productType, setProductType] = useState("")
  const [productTypeError, setProductTypeError] = useState("")
  const [productBrand, setProductBrand] = useState("")
  const [productBrandError, setProductBrandError] = useState("")
  const [model, setModel] = useState("")
  const [ModelError, setModelError] = useState("")
  const [variant, setVariant] = useState("")
  const [variantError, setVariantError] = useState("")
  const [loanAgree, setLoanAgree] = useState("")
  const [loanAgreeError, setLoanAgreeErroor] = useState("")
  const [docVerified, setDocVerified] = useState("")
  const [docVerifiedError, setDocVerifiedError] = useState("")
  const [regOwner, setRegOwner] = useState("")
  const [regOwnerError, setRegOwnerError] = useState("")
  const [dateOfReg, setDateOfReg] = useState("")
  const [dateOfRegError, setDateOfRegError] = useState("")
  const [dateOfMfg, setDateOfMfg] = useState("")
  const [dateOfMfgError, setDateOfMfgError] = useState("")
  const [numberPlate, setNumberPlate] = useState("")
  const [numberPlateError, setNumberPlateError] = useState("")
  const [engineNo, setEngineNo] = useState("")
  const [engineNoError, setEngineNoError] = useState("")
  const [chesis, setChesis] = useState("")
  const [chesisError, setChesisError] = useState("")
  const [bodyType, setBodyType] = useState("")
  const [bodyTypeError, setBodyTypeError] = useState("")
  const [meterReading, setMeterReading] = useState("")
  const [meterReadingError, setMeterReadingError] = useState("")
  const [fuelType, setFuelType] = useState("")
  const [fuelTypeError, setFuelTypeError] = useState("")
  const [vehicleColor, setVehicleColor] = useState("")
  const [vehicleColorError, setVehicleColorError] = useState("")
  const [grossWeight, setGrossWeight] = useState("")
  const [grossWeightError, setGrossWeightError] = useState("")
  const [ownerSerial, setOwnerSerial] = useState("")
  const [ownerSerialError, setOwnerSerialError] = useState("")
  const [hypothecation, setHypothecation] = useState("")
  const [hypothecationError, setHypothecationError] = useState("")
  const [insuranceCompany, setInsuranceCompany] = useState("")
  const [insuranceCompanyError, setInsuranceCompanyError] = useState("")
  const [insuranceExpiry, setInsuranceExpiry] = useState("")
  const [insuranceExpiryError, setInsuranceExpiryError] = useState("")
  const [cubicCapacity, setCubicCapacity] = useState("")
  const [cubicCapacityError, setCubicCapacityError] = useState("")
  const [seatingCapacity, setSeatingCapacity] = useState("")
  const [seatingCapacityError, setSeatingCapacityError] = useState("")
  const [exteriorRemark, setExteriorRemark] = useState("")
  const [exteriorRemarkError, setExteriorRemarkError] = useState("")
  const [regdNo, setRegdNo] = useState("")
  const [regdNoError, setRegdNoError] = useState("")
  const [backendEmployeesList, setBackendEmployeesList] = useState("")
  const [backendEmployee, setBackendEmployees] = useState("")
  const [managerList, setManagerList] = useState("")
  const [manager, setManager] = useState("")
  const [uniqueNames, setUniqueNames] = useState([]);
  const [uniqueType, setUniqueType] = useState([]);
  const [vehicleData, setVehicleData] = useState({});
  const [dataFetched, setDataFetched] = useState(false)
  const [role, setRole] = useState("")

  const [btnPress, setbtnPress] = useState(false);

  const [clientList, setClientList] = useState([])
  useEffect(() => {
    const client = totalClients.map((indClient, index) => ({
      id: index,
      _id: indClient._id,
      name: indClient.name,
      phone: indClient.phone,
      organisation: indClient.organisation
    }));
    setClientList(client);
  }, [totalClients])
  const selectCaseType = [
    {
      id: 1,
      name: "Revaluation",
    },
    {
      id: 2,
      name: "Repossession",
    },
    {
      id: 3,
      name: "Retail",
    },
    {
      id: 4,
      name: "Insurance Idv",
    },
    {
      id: 5,
      name: "Repurchase",
    },
  ];


  const stateList = [
    { id: 1, name: "Andhra Pradesh" },
    { id: 2, name: "Arunachal Pradesh" },
    { id: 3, name: "Assam" },
    { id: 4, name: "Bihar" },
    { id: 5, name: "Chhattisgarh" },
    { id: 6, name: "Goa" },
    { id: 7, name: "Gujarat" },
    { id: 8, name: "Haryana" },
    { id: 9, name: "Himachal Pradesh" },
    { id: 10, name: "Jharkhand" },
    { id: 11, name: "Karnataka" },
    { id: 12, name: "Kerala" },
    { id: 13, name: "Madhya Pradesh" },
    { id: 14, name: "Maharashtra" },
    { id: 15, name: "Manipur" },
    { id: 16, name: "Meghalaya" },
    { id: 17, name: "Mizoram" },
    { id: 18, name: "Nagaland" },
    { id: 19, name: "Odisha" },
    { id: 20, name: "Punjab" },
    { id: 21, name: "Rajasthan" },
    { id: 22, name: "Sikkim" },
    { id: 23, name: "Tamil Nadu" },
    { id: 24, name: "Telangana" },
    { id: 25, name: "Tripura" },
    { id: 26, name: "Uttar Pradesh" },
    { id: 27, name: "Uttarakhand" },
    { id: 28, name: "West Bengal" },
    { id: 29, name: "Andaman and Nicobar Islands" },
    { id: 30, name: "Chandigarh" },
    { id: 31, name: "Dadra and Nagar Haveli and Daman and Diu" },
    { id: 32, name: "Delhi" },
    { id: 33, name: "Ladakh" },
    { id: 34, name: "Lakshadweep" },
    { id: 35, name: "Puducherry" }
  ];
  const selectStateChange = (e) => {
    const { value } = e.target;
    setState(value);
  };
  useEffect(() => {
    if (state) {
      const formData = {
        country: 'India',
        state: state.toLocaleUpperCase(),
      };
      const getCity = async () => {
        try {
          const config = {
            headers: { 'Content-Type': 'application/json' },
          };
          const url = 'https://countriesnow.space/api/v0.1/countries/state/cities';
          const resp = await axios.post(url, formData, config);
          if (!resp.data.error) {
            const citiesData = resp.data.data.map((city, index) => ({
              id: index + 1,
              name: city.toLowerCase().replace(/\s/g, '_'),
            }));
            setCityList(citiesData);
          } else {
            console.error("Error in response:", resp.data.error); // Log the error for debugging
          }
        } catch (error) {
          console.error("Error:", error); // Log any other errors for debugging
        }
      };

      getCity();
    }
  }, [state]);

  useEffect(() => {
    if (loginData && loginData.role === 'businessAssociate') {
      const state = loginData.permittedState.map((indState, index) => ({
        id: index,
        name: indState
      }));
      setFilterState(state);
    }
  }, [loginData]);

  const selectCityChange = (e) => {
    const { value } = e.target;
    setCity(value);
  };
  const selectCaseChange = (e) => {
    const { value } = e.target;
    setCaseType(value);
  };
  const selectAssetType = [
    {
      id: 1,
      name: "Two Wheeler",
    },
    {
      id: 2,
      name: "Four Wheeler",
    },
    {
      id: 3,
      name: "Commercial",
    },
    {
      id: 4,
      name: "Construction Equipment",
    },
    {
      id: 5,
      name: "Three Wheeler",
    },
    {
      id: 6,
      name: "Farm Equipment",
    },
  ];
  const selectAssetChange = (e) => {
    const { value } = e.target;
    setAssetType(value);
  };

  const fuel = [
    { id: 1, name: 'Petrol' },
    { id: 2, name: 'Diesel' },
    { id: 3, name: 'Ev' },
  ];

  const selectFuelChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setFuelType(text);
  };

  const fourWheelerBody = [
    { name: 'Salsoon', id: 1 },
    { name: 'Sedans', value: 2 },
    { name: 'Hatchbacks', value: 3 },
    { name: 'SUVs', value: 4 },
    { name: 'MUVs', value: 5 },
    { name: 'Coupes', value: 6 },
  ];
  const constructionBody = [
    { name: 'Motor Graders', id: 1 },
    { name: 'Trenchers', id: 2 },
    { name: 'Excavators', id: 3 },
    { name: 'Common Dump Trucks', id: 4 },
    { name: 'Crawler Loaders', id: 5 },
    { name: 'Skid Steer Loaders', id: 6 },
    { name: 'Scrapers', id: 7 },
    { name: 'Bulldozers', id: 8 },
    { name: 'Backhoe Loader', id: 9 },
  ];
  const twoWheelerBody = [
    { name: 'standard', id: 1 },
    { name: 'cruiser', id: 2 },
    { name: 'touring', id: 3 },
    { name: 'sports', id: 4 },
    { name: 'off-road', id: 5 },
    { name: 'dual-purpose', id: 6 },
  ];

  const selectBodyTypeChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setBodyType(text);
  };

  const commercialVehicles = [
    {
      name: 'Tata Motors', value: 'Tata Motors'
    },
    {
      name: 'Ashok Leyland Limited', value: 'Ashok Leyland Limited'
    },
    {
      name: 'VE Commercial Vehicles Limited', value: 'VE Commercial Vehicles Limited'
    },
    {
      name: 'Mahindra & Mehindra Limited', value: 'Mahindra & Mehindra Limited'
    },
    {
      name: 'Scania Commercial Vehicle Pvt Ltd  ', value: 'Scania Commercial Vehicle Pvt Ltd'
    },
    {
      name: 'Piaggio India', value: 'Piaggio India'
    },
    {
      name: 'Force Motors', value: 'Force Motors'
    },
    {
      name: 'Bharat Benz', value: 'Bharat Benz'
    },
    {
      name: 'Volvo Trucks', value: 'Volvo Trucks'
    },
    {
      name: 'Swaraj Mazda-SML ISUZU', value: 'Swaraj Mazda-SML ISUZU'
    },
    {
      name: 'Maruti', value: 'Maruti'
    },
  ]
  const FarmEquipmentVehicles = [
    {
      name: 'Mahindra & Mehindra', value: 'Mahindra & Mehindra'
    },
    {
      name: 'Sonalika Tractors', value: 'Sonalika Tractors'
    },
    {
      name: 'John Deere India Pvt Ltd ', value: 'John Deere India Pvt Ltd'
    },
    {
      name: 'Preet Tractors', value: 'Preet Tractors'
    },
    {
      name: 'Force Motors', value: 'Force Motors'
    },
    {
      name: 'Escorts Agri Machinery', value: 'Escorts Agri Machinery'
    },
    {
      name: 'Swaraj Tractors', value: 'Swaraj Tractors'
    },
    {
      name: 'Tafe Motors', value: 'Tafe Motors'
    }
  ]
  const ConstructionEquipmentVehicles = [
    {
      name: 'Tata Hitachi', value: 'Tata Hitachi'
    },
    {
      name: 'JCB India Pvt  Ltd', value: 'JCB India Pvt  Ltd'
    },
    {
      name: 'Volvo Construction Eqp', value: 'Volvo Construction Eqp'
    },
    {
      name: 'L&T Construction Eqp', value: 'L&T Construction Eqp'
    },
    {
      name: 'SANY Constructions', value: 'SANY Constructions'
    },
    {
      name: 'Action Constrcution Eqp', value: 'Action Constrcution Eqp'
    },
    {
      name: 'Komatsu', value: 'Komatsu'
    },
    {
      name: 'Hyundai', value: 'Hyundai'
    }
  ]
  const ThreeWheelerVehicles = [
    {
      name: 'Bajaj Auto', value: 'Bajaj Auto'
    },
    {
      name: 'Piaggio Vehicles', value: 'Piaggio Vehicles'
    },
    {
      name: 'Mahindra & Mahindra', value: 'Mahindra & Mahindra'
    },
    {
      name: 'YC Electric vehicles', value: 'YC Electric vehicles'
    },
    {
      name: 'SAERA Electric Auto', value: 'SAERA Electric Auto'
    },
    {
      name: 'ATUL Auto', value: 'ATUL Auto'
    },
    {
      name: 'Champion Poly Plast', value: 'Champion Poly Plast'
    },
    {
      name: 'Mahindra Electric', value: 'Mahindra Electric'
    },
    {
      name: 'DILLI Electric Auto', value: 'DILLI Electric Auto'
    },
    {
      name: 'MINI Metro', value: 'MINI Metro'
    },
  ]

  useEffect(() => {
    const uniqueNamesArray = [];
    const uniquesTypesArray = []
    const flattenedArray = totalCars.reduce((acc, currentArray) => {
      return acc.concat(currentArray);
    }, []);
    flattenedArray.forEach(car => {
      if (!uniqueNamesArray.includes(car.Make)) {
        uniqueNamesArray.push(car.Make);
      }
      if (!uniquesTypesArray.includes(car.Type)) {
        uniquesTypesArray.push(car.Type)
      }
    });
    setUniqueNames(uniqueNamesArray);
    setUniqueType(uniquesTypesArray)
  }, [totalCars]);

  const selectProductBrandChange = (e) => {
    const { value } = e.target;
    setProductBrand(value);
  };
  const selectProductModelChange = (e) => {
    const { value } = e.target;
    setModel(value);
  };
  const selectProductVariantChange = (e) => {
    const { value } = e.target;
    setVariant(value);
  };
  const numberPlateList = [
    { id: 1, name: 'White' },
    { id: 2, name: 'Yellow ' },
    { id: 3, name: 'Blue ' },
    { id: 4, name: 'Black ' },
  ];
  const selectNumberPlateChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setNumberPlate(text);
  };

  useEffect(() => {
    if (btnPress === true) {
      if (assetType === '') {
        setAssetTypeError('Please enter asset type')
      } else {
        setAssetTypeError('')
      }
      if (caseType === '') {
        setCaseTypeError('Please enter case type')
      } else {
        setCaseTypeError('')
      }
      if (valuationPurpose === '') {
        setValuationPurposeError('Please enter valuation purpose')
      } else {
        setValuationPurposeError('')
      }
      if (requesterName === '') {
        setRequesterNameError('Please enter valuation purpose')
      } else {
        setRequesterNameError('')
      }
      if (requesterBranch === '') {
        setRequesterBranchError('Please enter valuation purpose')
      } else {
        setRequesterBranchError('')
      }
      if (requesterPhone === '') {
        setRequesterPhoneError('Please enter valuation purpose')
      } else {
        setRequesterPhoneError('')
      }
      if (name === "") {
        setNameError("please enter title");
      } else {
        setNameError("");
      }
      if (Cnfemail === "") {
        setEmailError("please enter email");
      } else {
        setEmailError("");
      }
      if (mobileCnf.length !== 10) {
        setMobileError("please enter mobile");
      } else {
        setMobileError("");
      }
      if (name === "") {
        setNameError("Please enter client name")
      } else {
        setNameError("")
      }
      if (address === "") {
        setAddressError("Please enter address")
      } else {
        setAddressError("")
      }
      if (state === "") {
        setStateError("Please enter state name")
      } else {
        setStateError("")
      }
      if (city === "") {
        setCityError("Please enter city name ")
      } else {
        setCityError("")
      }
      if (productBrand === "") {
        setProductBrandError("Please enter product brand name")
      } else {
        setProductBrandError("")
      }
      if (model === "") {
        setModelError("Please enter product model")
      }
      else {
        setModelError("")
      }
      if (variant === "") {
        setVariantError("Please select variant of the product")
      } else {
        setVariantError("")
      }
      if (regdNo === "") {
        setRegdNoError("Please enter reg no. of the product")
      } else {
        setRegdNoError("")
      }
    }
  }, [btnPress, assetType, caseType, valuationPurpose, requesterName, requesterBranch, requesterPhone, name, Cnfemail, vehicleLocation,
    mobileCnf, name, address, state, city, productBrand,
    model, variant, regdNo,
  ]);
  const handleRequesterPhn = async (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setRequesterPhone(value)
  }


  const setNumberChange = async (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setMobileError("");
    setMobileCnf(value)
    setMobileExistError('')
    if (value.length === 10) {
      setMobileCnf(value);
    } else {
      setMobileError("Please Enter Valid Number");
    }
  };
  const cancelClick = async (e) => {
    e.preventDefault();
    setAssetType('');
    setCaseType('')
    setValuationPurpose('')
    setEmail("");
    setCnfEmail("");
    setMobile("");
    setMobileCnf("");
    setName("");
    setVehicleLocation("");
    setAddress("");
    setState("");
    setCity("")
    setProductBrand("")
    setModel("")
    setVariant("")
    setRegdNo("")
  };
  const [error, setError] = useState(null);
  const handleRegdNo = async (e) => {
    const inputValue = e.target.value.toUpperCase();
    setRegdNo(inputValue);
    setDataFetched(false); // Reset data fetched status

    if (inputValue.length === 9 || inputValue.length === 10) {
      try {
        // let newData = await fetchVehicleData(inputValue);
        const formData = {
          "regdNo": inputValue,
        }
        const vehicle = await dispatch(sendVehicleNumber(formData))
        setVehicleData(vehicle.payload.response.result);
        setDataFetched(true);
        setError(null); // Reset error state
      } catch (error) {
        setError(error.message); // Set error state
        setVehicleData(null); // Reset vehicle data
      }
    } else {
      setVehicleData(null); // Reset vehicle data if input length is invalid
    }
  };

  const handleRequester = (e) => {
    const { organisation, phone, _id } = e.target.value
    setRequesterID(_id)
    setRequesterPhone(phone)
}

  useEffect(() => {
    // Fetch data again if required properties are null after initial fetch
    if (dataFetched && vehicleData) {
      const {
        rcFinancer,
        vehicleTaxUpto,
        permitValidUpto,
        permitType,
        vehicleSeatCapacity,
        grossVehicleWeight,
        regAuthority
      } = vehicleData;

      if (
        !rcFinancer ||
        !vehicleTaxUpto ||
        !permitValidUpto ||
        !permitType ||
        !vehicleSeatCapacity ||
        !grossVehicleWeight ||
        !regAuthority
      ) {
        // Required properties are still null, fetch again
        handleRegdNo({ target: { value: regdNo } });
      }
    }
  }, [dataFetched, vehicleData, regdNo]);


  const handlesubmit = async (e) => {
    e.preventDefault();
    if (assetType === '') {
      setAssetTypeError('Please enter asset type')
    } else {
      setAssetTypeError('')
    }
    if (caseType === '') {
      setCaseTypeError('Please enter case type')
    } else {
      setCaseTypeError('')
    }
    if (valuationPurpose === '') {
      setValuationPurposeError('Please enter valuation purpose')
    } else {
      setValuationPurposeError('')
    }
    if (requesterName === '') {
      setRequesterNameError('Please enter valuation purpose')
    } else {
      setRequesterNameError('')
    }
    if (requesterBranch === '') {
      setRequesterBranchError('Please enter valuation purpose')
    } else {
      setRequesterBranchError('')
    }
    if (requesterPhone === '') {
      setRequesterPhoneError('Please enter valuation purpose')
    } else {
      setRequesterPhoneError('')
    }
    if (name === "") {
      setNameError("please enter title");
    } else {
      setNameError("");
    }
    if (Cnfemail === "") {
      setEmailError("please enter email");
    } else {
      setEmailError("");
    }
    if (mobileCnf.length !== 10) {
      setMobileError("please enter mobile");
    } else {
      setMobileError("");
    }
    if (name === "") {
      setNameError("Please enter client name")
    } else {
      setNameError("")
    }
    if (address === "") {
      setAddressError("Please enter address")
    } else {
      setAddressError("")
    }
    if (state === "") {
      setStateError("Please enter state name")
    } else {
      setStateError("")
    }
    if (city === "") {
      setCityError("Please enter city name ")
    } else {
      setCityError("")
    }
    if (productBrand === "") {
      setProductBrandError("Please enter product brand name")
    } else {
      setProductBrandError("")
    }
    if (model === "") {
      setModelError("Please enter product model")
    }
    else {
      setModelError("")
    }
    if (variant === "") {
      setVariantError("Please select variant of the product")
    } else {
      setVariantError("")
    }
    if (regdNo === "") {
      setRegdNoError("Please enter reg no. of the product")
    } else {
      setRegdNoError("")
    }
    if (name !== "" && Cnfemail !== "" && mobileCnf !== ""
      && address !== "" && requesterName !== '' && requesterBranch !== '' && requesterPhone !== ''
      && state !== "" && city !== "" && productBrand !== ""
      && model !== "" && variant !== "" && regdNo !== ""
    ) {
      const formData = {
        assetType: assetType,
        caseType: caseType,
        valuationPurpose: valuationPurpose,
        vehicleLocation: vehicleLocation,
        requesterID: requesterID,
        requesterName: requesterName,
        requesterBranch: requesterBranch,
        requesterPhone: requesterPhone,
        name: name,
        mobile: mobileCnf,
        email: Cnfemail,
        address: address,
        state: state,
        city: city,
        regdNo: regdNo,
        brand: productBrand,
        model: model,
        variant: variant,
        vehicleEdetails: {
          blacklistStatus: vehicleData.blacklistStatus,
          bodyType: vehicleData.bodyType,
          chassis: vehicleData.chassis,
          engine: vehicleData.engine,
          grossVehicleWeight: vehicleData.grossVehicleWeight,
          isCommercial: vehicleData.isCommercial,
          model: vehicleData.model,
          normsType: vehicleData.normsType,
          owner: vehicleData.owner,
          ownerCount: vehicleData.ownerCount,
          ownerFatherName: vehicleData.ownerFatherName,
          permanentAddress: vehicleData.permanentAddress,
          presentAddress: vehicleData.presentAddress,
          rcExpiryDate: vehicleData.rcExpiryDate,
          rcFinancer: vehicleData.rcFinancer,
          rcStandardCap: vehicleData.rcStandardCap,
          regAuthority: vehicleData.regAuthority,
          regDate: vehicleData.regDate,
          regNo: vehicleData.regNo,
          status: vehicleData.status,
          statusAsOn: vehicleData.statusAsOn,
          type: vehicleData.type,
          unladenWeight: vehicleData.unladenWeight,
          vehicleCategory: vehicleData.vehicleCategory,
          vehicleClass: vehicleData.vehicleClass,
          vehicleColour: vehicleData.vehicleColour,
          vehicleCubicCapacity: vehicleData.vehicleCubicCapacity,
          vehicleCylindersNo: vehicleData.vehicleCylindersNo,
          vehicleInsuranceCompanyName: vehicleData.vehicleInsuranceCompanyName,
          vehicleInsurancePolicyNumber: vehicleData.vehicleInsurancePolicyNumber,
          vehicleInsuranceUpto: vehicleData.vehicleInsuranceUpto,
          vehicleManufacturerName: vehicleData.vehicleManufacturerName,
          vehicleManufacturingMonthYear: vehicleData.vehicleManufacturingMonthYear,
          vehicleNumber: vehicleData.vehicleNumber,
          vehicleSeatCapacity: vehicleData.vehicleSeatCapacity,
          vehicleTaxUpto: vehicleData.vehicleTaxUpto,
          permitValidUpto: vehicleData.permitValidUpto,
          permitType: vehicleData.permitType,
          wheelbase: vehicleData.wheelbase,
        },
      };
      console.log(formData, 'formData');
      const newcase = await dispatch(createCase(formData))
      if (newcase.payload.success) {
        alert("Case created successfully");
        setEmail("");
        setCnfEmail("");
        setMobile("");
        setMobileCnf("");
        setName("");
        setRole("")
        setAddress("")
        setState("")
        setCity("")
        setProductBrand("")
        setModel("")
        setVariant("")
        setRegdNo("")
        setRequesterName('')
        setRequesterPhone('')
        setRequesterBranch('')
        setbtnPress(false)
        navigate("/admin/assignedcases")
      }
    } else {
      setbtnPress(true);
    }
  };
  console.log(requesterName, 'requesterName');

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div className="flex items-center justify-between py-2 px-5"
              style={{ backgroundColor: '#4D44B5', color: '#fff', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
            >
              <h4 className="text-lg font-medium text-default-950 capitalize" style={{ color: '#fff' }}>
                Create Case
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-3 gap-4">
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: '500', color: '#000066' }}
                  >
                    Case Type :
                  </label>
                  <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                    <InputLabel id="demo-simple-Case-Type" className="inputcenter">Case Type</InputLabel>
                    <Select
                      labelId="demo-simple-Case-Type"
                      id="demo-simple-select"
                      value={caseType}
                      label="Case Type"
                      onChange={(e) => selectCaseChange(e)}
                      style={{ height: '35px' }}
                      inputProps={{ style: { textAlign: 'center' } }}
                    >
                      {selectCaseType.map((test, index) =>
                        <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fAssetType"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: '500', color: '#000066' }}
                  >
                    Asset Type :
                  </label>
                  <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                    <InputLabel id="demo-simple-Asset Type" className="inputcenter">Asset Type</InputLabel>
                    <Select
                      labelId="demo-simple-Asset Type"
                      id="demo-simple-select"
                      value={assetType}
                      label="Asset Type"
                      onChange={(e) => selectAssetChange(e)}
                      style={{ height: '35px' }}
                      inputProps={{ style: { textAlign: 'center' } }}
                    >
                      {selectAssetType.map((test, index) =>
                        <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fValuationPurpose"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: '500', color: '#000066' }}
                  >
                    Purpose Of Valuation :
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Purpose Of Valuation"
                      variant="outlined"
                      className={
                        valuationPurposeError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: '3px' }}
                      type="text"
                      id="text"
                      value={valuationPurpose}
                      onChange={(e) => setValuationPurpose(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: '500', color: '#000066' }}
                  >
                    Valuation Requested By * (Client)
                  </label>
                  <FormControl fullWidth style={{ marginTop: '5px' }} className="textfield">
                    <InputLabel id="demo-simple-requesterName" className="inputcenter">Valuation Requested By</InputLabel>
                    <Select
                      labelId="demo-simple-requesterName"
                      id="demo-simple-select"
                      value={requesterName}
                      label="requesterName"
                      onChange={(e) => {handleRequester(e); setRequesterName(e.target.value.name) }}
                      style={{ height: '38px' }}
                      inputProps={{ style: { textAlign: 'center' } }}
                    >
                      {clientList.map((test, index) =>
                        <MenuItem value={test} key={index} selected={test.name === requesterName ? true : false}>{test.name}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: '500', color: '#000066' }}
                  >
                    Valuation Requested By * (Customer)
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Valuation Requester Name"
                      variant="outlined"
                      className={"textfield"}
                      style={{ marginTop: '5px' }}
                      type="text"
                      id="text"
                      value={requesterName}
                      onChange={(e) => setRequesterName(e.target.value)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: '500', color: '#000066' }}
                  >
                    Requester Branch Name *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Requester Branch Name"
                      variant="outlined"
                      className={
                        requesterBranchError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: '5px' }}
                      type="text"
                      id="text"
                      value={requesterBranch}
                      onChange={(e) => setRequesterBranch(e.target.value)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="lName"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: '500', color: '#000066' }}
                  >
                    Requester Phone *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Requester Phone"
                      variant="outlined"
                      className={
                        requesterPhoneError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: '5px' }}
                      type="text"
                      id="text"
                      value={requesterPhone}
                      onChange={(e) => handleRequesterPhn(e)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: '500', color: '#000066' }}
                  >
                    Customer Name *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Customer Name"
                      variant="outlined"
                      className={
                        nameError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: '5px' }}
                      type="text"
                      id="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="lName"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: '500', color: '#000066' }}
                  >
                    Customer Phone *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Customer Phone"
                      variant="outlined"
                      className={
                        mobileError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: '5px' }}
                      type="text"
                      id="text"
                      value={mobileCnf}
                      onChange={(e) => setNumberChange(e)}
                    // className={classes.textfield}
                    />
                  </div>
                  <div>{mobileExistError}</div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="lName"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: '500', color: '#000066' }}
                  >
                    Customer Email *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Customer Email"
                      variant="outlined"
                      className={
                        emailError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: '5px' }}
                      type="text"
                      id="text"
                      value={Cnfemail}
                      onChange={(e) => setCnfEmail(e.target.value)}
                    // className={classes.textfield}
                    />
                  </div>
                  <div>{emailExistError}</div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="Address"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: '500', color: '#000066' }}
                  >
                    Customer Address *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Customer Address"
                      variant="outlined"
                      className={
                        addressError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: '5px' }}
                      type="text"
                      id="text"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fState"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: '500', color: '#000066' }}
                  >
                    Select State *
                  </label>
                  <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                    <InputLabel id="demo-simple-State-Type">State Type</InputLabel>
                    <Select
                      labelId="demo-simple-State-Type"
                      id="demo-simple-select"
                      value={state}
                      label="State"
                      onChange={(e) => selectStateChange(e)}

                    >
                      {
                        loginData.role === 'businessAssociate' ?
                          filterState.map((test, index) =>
                            <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                          ) : stateList.map((test, index) =>
                            <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                          )
                      }

                    </Select>
                  </FormControl>
                </div>
                {
                  cityList.length > 0 ?
                    <div className="space-y-1">
                      <label
                        htmlFor="fCity"
                        className="text-sm font-medium text-default-900"
                        style={{ fontWeight: '500', color: '#000066' }}
                      >
                        Select City *
                      </label>
                      <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                        <InputLabel id="demo-simple-City-Type">City Type</InputLabel>
                        <Select
                          labelId="demo-simple-City-Type"
                          id="demo-simple-select"
                          value={city}
                          label="City"
                          onChange={(e) => selectCityChange(e)}

                        >
                          {cityList.map((test, index) =>
                            <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </div>
                    :
                    <></>
                }
                <div className="space-y-1">
                  <label
                    htmlFor="regdNo"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: '500', color: '#000066' }}
                  >
                    RegdNo *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Regd No"
                      variant="outlined"
                      className={
                        regdNoError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: '5px' }}
                      type="text"
                      id="text"
                      value={regdNo}
                      onChange={(e) => setRegdNo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="vehcileLocation"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: '500', color: '#000066' }}
                  >
                    Vehicle Location *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Vehicle Location"
                      variant="outlined"
                      className={
                        vehicleLocationError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: '5px' }}
                      type="text"
                      id="text"
                      value={vehicleLocation}
                      onChange={(e) => setVehicleLocation(e.target.value)}
                    />
                  </div>
                </div>
                {
                  assetType === "Commercial" &&
                  <div className=" space-y-2">
                    <div className="w-full">
                      <div className="mb-2">
                        <label
                          htmlFor="choices-vehicle-brand"
                          className="form-label text-sm font-medium text-default-800 dark:text-zinc-100 studenth4-label"
                          style={{ fontWeight: '500', color: '#000066' }}
                        >
                          Choose Vehicle Brand *
                        </label>
                      </div>
                      <FormControl fullWidth style={{ marginTop: '5px' }} className="textfield">
                        <InputLabel id="demo-simple-Vehicle-Brand">Vehicle Brand</InputLabel>
                        <Select
                          labelId="demo-simple-Vehicle-Brand"
                          id="demo-simple-select"
                          value={productBrand}
                          label="Product Brand"
                          onChange={(e) => selectProductBrandChange(e)}

                        >
                          {commercialVehicles.map((test, index) =>
                            <MenuItem value={test.value} key={index}>{test.name}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                }
                {
                  assetType === "Farm Equipment" &&
                  <div className=" space-y-2">
                    <div className="w-full">
                      <div className="mb-2">
                        <label
                          htmlFor="choices-vehicle-brand"
                          className="form-label text-sm font-medium text-default-800 dark:text-zinc-100 studenth4-label"
                          style={{ fontWeight: '500', color: '#000066' }}
                        >
                          Choose Vehicle Brand *
                        </label>
                      </div>
                      <FormControl fullWidth style={{ marginTop: '5px' }} className="textfield">
                        <InputLabel id="demo-simple-Vehicle-Brand">Vehicle Brand</InputLabel>
                        <Select
                          labelId="demo-simple-Vehicle-Brand"
                          id="demo-simple-select"
                          value={productBrand}
                          label="Product Brand"
                          onChange={(e) => selectProductBrandChange(e)}

                        >
                          {FarmEquipmentVehicles.map((test, index) =>
                            <MenuItem value={test.value} key={index}>{test.name}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                }
                {
                  assetType === "Construction Equipment" &&
                  <div className=" space-y-2">
                    <div className="w-full">
                      <div className="mb-2">
                        <label
                          htmlFor="choices-vehicle-brand"
                          className="form-label text-sm font-medium text-default-800 dark:text-zinc-100 studenth4-label"
                          style={{ fontWeight: '500', color: '#000066' }}
                        >
                          Choose Vehicle Brand *
                        </label>
                      </div>
                      <FormControl fullWidth style={{ marginTop: '5px' }} className="textfield">
                        <InputLabel id="demo-simple-Vehicle-Brand">Vehicle Brand</InputLabel>
                        <Select
                          labelId="demo-simple-Vehicle-Brand"
                          id="demo-simple-select"
                          value={productBrand}
                          label="Product Brand"
                          onChange={(e) => selectProductBrandChange(e)}

                        >
                          {ConstructionEquipmentVehicles.map((test, index) =>
                            <MenuItem value={test.value} key={index}>{test.name}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                }
                {
                  assetType === "Three Wheeler" &&
                  <div className=" space-y-2">
                    <div className="w-full">
                      <div className="mb-2">
                        <label
                          htmlFor="choices-vehicle-brand"
                          className="form-label text-sm font-medium text-default-800 dark:text-zinc-100 studenth4-label"
                          style={{ fontWeight: '500', color: '#000066' }}
                        >
                          Choose Vehicle Brand *
                        </label>
                      </div>
                      <FormControl fullWidth style={{ marginTop: '5px' }} className="textfield">
                        <InputLabel id="demo-simple-Vehicle-Brand">Vehicle Brand</InputLabel>
                        <Select
                          labelId="demo-simple-Vehicle-Brand"
                          id="demo-simple-select"
                          value={productBrand}
                          label="Product Brand"
                          onChange={(e) => selectProductBrandChange(e)}

                        >
                          {ThreeWheelerVehicles.map((test, index) =>
                            <MenuItem value={test.value} key={index}>{test.name}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                }
                {
                  assetType === "Four Wheeler" &&
                  <div className=" space-y-2">
                    <div className="w-full">
                      <div className="mb-2">
                        <label
                          htmlFor="choices-vehicle-brand"
                          className="form-label text-sm font-medium text-default-800 dark:text-zinc-100 studenth4-label"
                          style={{ fontWeight: '500', color: '#000066' }}
                        >
                          Choose Vehicle Brand *
                        </label>
                      </div>
                      <FormControl fullWidth style={{ marginTop: '5px' }} className="textfield">
                        <InputLabel id="demo-simple-Vehicle-Brand">Vehicle Brand</InputLabel>
                        <Select
                          labelId="demo-simple-Vehicle-Brand"
                          id="demo-simple-select"
                          value={productBrand}
                          label="Product Brand"
                          onChange={(e) => selectProductBrandChange(e)}

                        >
                          {uniqueNames.map((test, index) =>
                            <MenuItem value={test} key={index}>{test}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                }
                {
                  assetType === "Two Wheeler" &&
                  <div className=" space-y-2">
                    <div className="w-full">
                      <div className="mb-2">
                        <label
                          htmlFor="choices-vehicle-model"
                          className="form-label text-sm font-medium text-default-800 dark:text-zinc-100 studenth4-label"
                          style={{ fontWeight: '500', color: '#000066' }}
                        >
                          Vehicle Brand Name *
                        </label>
                      </div>
                      <div className="textfieldcontainer">
                        <TextField
                          label="Brand Name"
                          variant="outlined"
                          className={
                            "textfield"
                          }
                          style={{ marginTop: '5px' }}
                          type="text"
                          id="text"
                          value={productBrand}
                          onChange={(e) => setProductBrand(e.target.value)}
                        // className={classes.textfield}
                        />
                      </div>
                    </div>
                  </div>
                }
                <div className=" space-y-2">
                  <div className="w-full">
                    <div className="mb-2">
                      <label
                        htmlFor="choices-vehicle-model"
                        className="form-label text-sm font-medium text-default-800 dark:text-zinc-100 studenth4-label"
                        style={{ fontWeight: '500', color: '#000066' }}
                      >
                        Vehicle Model Name *
                      </label>
                    </div>
                    <div className="textfieldcontainer">
                      <TextField
                        label="Model Name"
                        variant="outlined"
                        className={
                          "textfield"
                        }
                        style={{ marginTop: '5px' }}
                        type="text"
                        id="text"
                        value={model}
                        onChange={(e) => setModel(e.target.value)}
                      // className={classes.textfield}
                      />
                    </div>
                  </div>
                </div>
                <div className=" space-y-2">
                  <div className="w-full">
                    <div className="mb-2">
                      <label
                        htmlFor="choices-vehicle-model"
                        className="form-label text-sm font-medium text-default-800 dark:text-zinc-100 studenth4-label"
                        style={{ fontWeight: '500', color: '#000066' }}
                      >
                        Vehicle Variant Name *
                      </label>
                    </div>
                    <div className="textfieldcontainer">
                      <TextField
                        label="Variant Name"
                        variant="outlined"
                        className={
                          "textfield"
                        }
                        style={{ marginTop: '5px' }}
                        type="text"
                        id="text"
                        value={variant}
                        onChange={(e) => setVariant(e.target.value)}
                      // className={classes.textfield}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex items-center gap-2 justify-end"
            style={{ marginBottom: "80px" }}
          >
            <button
              onClick={(e) => cancelClick(e)}
              style={{
                background: "red",
                color: "#fff",
                border: "none",
                outline: "none",
              }}
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
            >
              <i className="ph-bold ph-x text-lg" /> Reset
            </button>
            <button
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
              style={{
                background: "#4D44B5",
                border: "none",
                outline: "none",
              }}
              onClick={(e) => handlesubmit(e)}
            >
              <i className="ph-duotone ph-cloud-arrow-up text-lg" />
              Create
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCase;
